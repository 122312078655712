import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Form, Input, CustomHelmet, Loading, ColourPicker, Banner } from '@openbox-app-shared'
import { subscribe, dispatch } from '../../redux'
import { state as reduxHandlerState } from '../../components/ReduxHandler'

import tabApplicationBlocks from './tabs/applicationBlocks'
import tabDeploy from './tabs/deploy'
import tabAppearance from './tabs/appearance'
import tabProduct from './tabs/product'
import tabLocation from './tabs/location'
import tabTags from './tabs/tags'
import tabAdvanced from './tabs/advanced'

import Box from '../../components/Box'
import TabBar from '../../components/TabBar'
import CopyUrl from '../../components/CopyUrl'
import User from '../../components/User'
import SaveButton from '../../components/SaveButton'
import dashboardIcons from '../../icons'

import { log } from '../../log'
import MainFrame from '../../components/MainFrame'
import ColourPickers from '../../components/ColourPickers'
import HeaderBar from '../../components/HeaderBar'

const StyledRoute = styled.div`
  padding: 1rem;
  aside {
    hr {
      border-color: #CFD8DC;
    }
    .buttons {
      height: 2.5rem;
      margin-bottom: 1rem;
      > * {
        float: left;
      }
      > * + * {
        margin-left: 0.5rem;
      }
    }
  }
  p .code-guide-inline + .code-guide-inline {
    margin-left: 0.5rem;
  }
  .component--box {
    .component--h1 {
      margin-bottom: 1rem;
    }
    .component--h2 {
      margin-bottom: 0.5rem;
    }
    .component--details {
      width: 100%;
    }
    .component--form + .component--details, .component--list + .component--details, .component--list + .component--form, .component--details + .component--button {
      margin-top: 1rem;
    }
    label + .component--list {
      margin-top: 0.5rem;
    }
  }
  .component--banner + .component--box {
    margin-top: 1rem;
  }
  .component--tabbar .tab-container {
    > * {
      margin-top: 1rem;
    }
  }
`

export default class Route extends Component {
  constructor() {
    super()
    this.previewLinkCounter = 0
    this.state = {
      application: undefined,
      hasMadeUpdate: false
    }
    this.onUpdate = this.onUpdate.bind(this)
    this.onSave = this.onSave.bind(this)
    this.setCurrency = this.setCurrency.bind(this)
  }

  async componentDidMount() {
    const { user } = this.props
    const { applicationId } = this.props.match.params
    const application = await window.sticky.applications.get(applicationId)
    log('[Route-application] [componentDidMount]', { application })
    const applicationBlocks = await window.sticky.applications.blocks.getAll(user)
    const productCategories = await window.sticky.products.categories.getAll()
    const products = await window.sticky.products.getAll()

    if (!(user.federatedUserCan('applications') && user.federatedUserCan('applications-advanced'))) {
      return window.sticky.applications.blocks.showError('You do not have permission to work on flows.')
    } else {
      this.setState({
        application,
        applicationBlocks,
        productCategories,
        products,
        previewLink: this.getPreviewLink(application, undefined, true),
        liveLink: this.getPreviewLink(application, undefined, false)
      })
    }
    this.subscriptions = [
      subscribe(
        'APPLICATION_BASE_CREATE_GOOD',
        ({ why, id }) => {
          why === 'application--advanced' && (() => {
            dispatch('REDIRECT', { to: '/my/account/flow-templates' })
            dispatch('BLINK', { ids: [id] })
          })()
        }
      ),
      subscribe(
        'SESSION_BECOME_RESET',
        () => {
          this.setState({
            previewLink: this.getPreviewLink(application, undefined, true),
            liveLink: this.getPreviewLink(application, undefined, false)
          })
        }
      )
    ]
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { application } = this.state
    const { view: oldView, viewSubroot: oldViewSubroot } = this.props.match.params
    const { view: newView, viewSubroot: newViewSubroot } = nextProps.match.params
    if (['steps', undefined].includes(oldView) && newView === 'steps' && oldViewSubroot !== newViewSubroot && ['on_load', 'on_pay_before', 'on_pay', 'on_pay_fail', 'on_pay_card'].includes(newViewSubroot)) {
      this.setState(
        {
          previewLink: this.getPreviewLink(application, nextProps.match.params, true),
          liveLink: this.getPreviewLink(application, nextProps.match.params, false)
        }
      )
      return false
    }
    return true
  }

  componentWillUnmount() {
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  getPreviewLink(application, matchParams = this.props.match.params, includePreviewLinkCounter) {
    const { view: fsView, viewSubroot: fsViewSubroot } = matchParams
    const { SESSION_BECOME } = reduxHandlerState
    const sessionId = SESSION_BECOME ? SESSION_BECOME.session.id : undefined
    const doForceState = (() => {
      if (fsView === 'steps' && fsViewSubroot === 'on_pay') {
        return 'good--'
      }
      if (fsView === 'steps' && fsViewSubroot === 'on_pay_fail') {
        return 'bad--[Reason why the payment failed]'
      }
      if (fsView === 'steps' && fsViewSubroot === 'on_pay_before') {
        return 'on_pay_before--'
      }
      if (fsView === 'steps' && fsViewSubroot === 'on_pay_card') {
        return 'on_pay_card--'
      }
      return undefined
    })()
    this.previewLinkCounter += 1
    let finalUrl = window.sticky.applications.test(application.id)
    if (includePreviewLinkCounter) {
      finalUrl += `${(finalUrl.indexOf('?') >= 0 ? '&' : '?')}previewLinkCounter=${this.previewLinkCounter}`
    }
    if (doForceState) {
      finalUrl += `${(finalUrl.indexOf('?') >= 0 ? '&' : '?')}forceState=${doForceState}`
    }
    if (sessionId) {
      finalUrl += `${(finalUrl.indexOf('?') >= 0 ? '&' : '?')}sessionId=${sessionId}&actingAs=${sessionId}`
    }
    return finalUrl
  }

  onUpdate(payload, extraState = {}) {
    let { application } = this.state
    log('[Route-application] [onUpdate] 1', { payload, application })
    application.patch(payload)
    log('[Route-application] [onUpdate] 2', { payload, application })
    return new Promise(resolve => {
      this.setState(
        {
          application,
          hasMadeUpdate: true,
          ...extraState
        },
        () => resolve(application)
      )
    })
  }

  async onSave() {
    log('[Route-application] [onSave]')
    const { application } = this.state
    try {
      await window.sticky.applications.save(application)
      this.setState(
        {
          hasMadeUpdate: false,
          previewLink: this.getPreviewLink(application, undefined, true),
          liveLink: this.getPreviewLink(application, undefined, false)
        }
      )
    } catch ({ message }) {
      dispatch('SHOW_MESSAGE', { message: <><p><strong>Hmm; that didn't work.</strong></p><p>{message}</p></>, canBeBadded: '' })
      this.setState(
        {
          hasMadeUpdate: false
        }
      )
    }
  }

  setCurrency(id) {
    log('[Route-application] [setCurrency]', { id })
    this.onUpdate({ 'stickypayCurrency': id })
  }

  render() {
    const debouncedProps = {
      onChange: (key, value) => this.onUpdate({ [key]: value }),
      // onDebouncedChange: this.onSave
    }

    const {
      user,
      match
    } = this.props
    const {
      view
    } = match.params
    const {
      application,
      applicationBlocks,
      productCategories,
      hasMadeUpdate,
      previewLink,
      liveLink
    } = this.state

    log('[Route-application] [render]', { user, application })

    const isReady = [application, applicationBlocks, productCategories].every(e => e) ? true : false

    const tabs = [
      tabApplicationBlocks,
      tabDeploy,
      tabAppearance,
      tabProduct,
      tabTags,
      tabLocation,
      tabAdvanced
    ]
    const renderTabs = isReady ? tabs
      .map(tab => ({
        ...tab,
        name: tab.name && tab.name(this),
        to: tab.to(this),
        child: tab.child(this, { debouncedProps, applicationBlocks, previewLink })
      }))
      .filter(tab => tab.child) : []

    return (
      <StyledRoute>
        <CustomHelmet
          title={application && application.name}
        />
        {!isReady && <Loading />}
        {isReady && <>
          <MainFrame
            user={user}
            autoUi={this.props.autoUi}
            aside={<>
              <User user={user} whichPart={match.path} toShow={['semantic-home']} autoUi={this.props.autoUi} />
              <hr />
              <div className='buttons'>
                <SaveButton
                  color='#26de81'
                  canSave={hasMadeUpdate}
                  onSave={this.onSave}
                />
              </div>
              {application.lightMode && (
                <Banner>
                  <p>
                    Flow "Light mode" is on. Payments and products won't work.
                  </p>
                </Banner>
              )}
              <Box>
                <Form {...debouncedProps}>
                  <Input
                    name='name'
                    label='Name'
                    value={application.name}
                    autocomplete='off'
                  />
                </Form>
              </Box>
              <Box>
                <ColourPickers>
                  <Form {...debouncedProps}>
                    <ColourPicker
                      name='primaryColor'
                      label='Main'
                      currentColour={application.primaryColor}
                    />
                    <ColourPicker
                      name='backgroundColor'
                      label='Background'
                      currentColour={application.backgroundColor}
                    />
                  </Form>
                </ColourPickers>
              </Box>
              <Box>
                <CopyUrl url={liveLink} buttonProps={{ backgroundColor: '#26de81' }} />
              </Box>
            </>}
            main={
              <>
                <HeaderBar text={`Flows › ${application.name}`} Icon={dashboardIcons.application} user={user} />
                <Box>
                  <TabBar
                    selectedTab={view}
                    tabs={renderTabs}
                  />
                </Box>
              </>
            }
          />
        </>}
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  applicationId: PropTypes.string,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
