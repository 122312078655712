import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import { CustomHelmet, Button, LinkButton } from '@openbox-app-shared'
import { dispatch } from '../../redux'

import _ from '../../_'

import dashboardIcons from '../../icons'
import PartnerCell from '../../../../../openbox-app-shared/components/PartnerCell'
import getLogOutUrl from '../../getLogOutUrl'

const StyledRoute = styled.div`
  min-height: 100vh;
  section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 1rem;
    width: 100%;
    max-width: 20rem;
    > * {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    > *.full-width {
      width: 100% !important;
    }
    > * + * {
      margin-top: 1rem;
    }
    .component--button > button {
      width: 100%;
    }
  }
`

export default class Route extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    dispatch('LOADING')
    ;(async () => {
      const applications = await window.sticky.applications.getAll()
      this.setState({
        applicationsWithWellKnownIdentifiers: applications.filter(_ => ['ONBOARDING_MERCHANT_VIRTUAL_TERMINAL'].includes(_.wellKnownIdentifier))
      })
      dispatch('STOP_LOADING')
    })()
  }

  render() {
    const { user, autoUi } = this.props

    const federatedUserId = user.federatedUser ? user.federatedUser.id : undefined
    const federatedUserPrivateKey = user.federatedUser ? user.federatedUser.privateKey : undefined

    const { applicationsWithWellKnownIdentifiers } = this.state
    if (!Array.isArray(applicationsWithWellKnownIdentifiers)) {
      return null
    }
    return (
      <StyledRoute
        className='level--styled-route'
      >
        <CustomHelmet
          title={window.sticky._('STICKY_PAY')}
        />
        <section>
          <PartnerCell partner={user.partner} />
          {applicationsWithWellKnownIdentifiers.map((_, i) => (
            <Button
              key={_.id}
              sameTab
              onClick={() => {
                window.sticky.popUpIframe({
                  src: window.sticky.applications.test(_.id, (federatedUserId && federatedUserPrivateKey) && `ifFederatedUserFederatedUserId=${federatedUserId}&ifFederatedUserFederatedUserPrivateKey=${federatedUserPrivateKey}`),
                  maxWidth: '376px',
                  maxHeight: '680px',
                  showBlocker: false
                })
              }}
              InlineIcon={dashboardIcons.deploy}
              bigIcon
              className={classnames('full-width', 'partner-me', { 'openbox--pulsing-1': i === 0 })}
            >
              {_.name}
            </Button>
          ))}
          <LinkButton
            sameTab
            to='/sticky-pay-sticky-pay?autoUi=backToYeetYeet'
            InlineIcon={dashboardIcons.pos}
            bigIcon={applicationsWithWellKnownIdentifiers.length === 0}
            className={classnames('full-width', 'partner-me', { 'openbox--pulsing-1': applicationsWithWellKnownIdentifiers.length === 0 })}
          >
            {window.sticky._('STICKY_PAY')}
          </LinkButton>
          {user.federatedUserCan('live-payments') && <LinkButton
            sameTab
            to='/to-do?autoUi=backToYeetYeet'
            InlineIcon={dashboardIcons.livePayments}
            bigIcon={applicationsWithWellKnownIdentifiers.length === 0}
            className='full-width partner-me'
          >
            Live payments
          </LinkButton>}
          {user.can('show-linear-operator-view') && <LinkButton
            sameTab
            to='/to-do-linear'
            InlineIcon={dashboardIcons.livePaymentsLinear}
            bigIcon={applicationsWithWellKnownIdentifiers.length === 0}
            className='full-width partner-me'
          >
            Linear live payments
          </LinkButton>}
          <LinkButton
            sameTab
            to='/me/flows?autoUi=backToYeetYeet'
            InlineIcon={dashboardIcons.application}
            bigIcon={applicationsWithWellKnownIdentifiers.length === 0}
            className='full-width partner-me'
          >
            Dashboard
          </LinkButton>
          {autoUi.includes('browserBack') && <Button
            onClick={() => {
              window.sticky.users.logOut()
              window.history.back()
            }}
            backgroundColor='transparent'
            InlineIcon={dashboardIcons.goBack}
          >
            Back
          </Button>}
          {!autoUi.includes('browserBack') && <Button
            onClick={() => {
              window.sticky.users.logOut()
              const logOutRedirectTo = getLogOutUrl()
              window.location = `/log-in${logOutRedirectTo ? `?logInRedirectTo=${encodeURIComponent(logOutRedirectTo)}` : ''}`
            }}
            backgroundColor='transparent'
            InlineIcon={dashboardIcons.logOut}
          >
            Log out
          </Button>}
        </section>
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
