/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { tags, icons, isPrivateKey, Button, Switch, List, ListItem, Price, ExpandCollapse, ExpandCollapseContainer } from '@openbox-app-shared'
import dashboardIcons from '../icons'
import H1 from '../components/H1'
import _ from '../_'
import { log } from '../log'
import { subscribe, dispatch } from '../redux'

import Box from '../components/Box'
import FilterButton from '../components/FilterButton'
import classnames from 'classnames'

const StyledComponent = styled.div`
  .component--h2 {
    margin-bottom: 0.5rem;
  }
  > .buttons > .component--switch {
    margin-top: 0.25rem !important;
  }
  .tax-borked-products {
    max-width: 32rem;
    margin-bottom: 1rem;
    background-color: #fad859;
    > h1, > p {
      color: #a48204;
    }
    > * + * {
      margin-top: 1rem;
    }
  }
  .product-category {
    position: relative;
    max-width: 32rem;
    box-shadow: 0 2px 4px 0 rgba(60, 68, 86, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    > div {
      padding: 0.5rem 0.25rem 0.5rem 0.25rem;
      > p {
        margin-bottom: 0.5rem;
      }
    }
    &:not(.is-enabled) > summary {
      opacity: 0.5;
    }
    &.has-buttons {
      > summary {
        margin-right: 5.5rem;
      }
      > div {
        padding-bottom: 0;
      }
    }
    .component--list + .component--button {
      margin-top: 1rem;
    }
    .component--list + .buttons {
      margin-top: 1rem !important;
    }
    .buttons {
      margin-top: 0 !important;
      padding-left: 0.5rem;
    }
    .component--button.control-tr {
      position: absolute;
      top: 0.5rem;
      padding: 0.25rem;
      width: auto;
      &.select-all {
        right: 5.5rem;
      }
      &.edit {
        right: 3rem;
      }
      &.delete {
        right: 0.5rem;
      }
      &.sort {
        right: 5.6rem;
      }
    }
  }
  .product-category + .product-category {
    margin-top: 1rem;
  }
  .component--expand-collapse-container + .product-category, .product-category + .component--expand-collapse-container {
    margin-top: 1rem;
  }
  .managed {
    color: #fad859;
  }
`

const stockControlLoad = () => (window.sticky.internals.readFromLocalStorage('dashboard--stock-control') || false)
const stockControlSave = v => window.sticky.internals.writeToLocalStorage('dashboard--stock-control', v)

function ProductListItem({ doStockControl, prefix, product, currentProductId, canGoToProduct, showActions, onAction, masterProductCategory, onChangeCurrentProductId, selectMode, selectedIds, setSelectedIndices }) {
  const tags = [
    !doStockControl && {
      name: <Price price={product.price} currency={product.currency} />,
      color: '#a55eea',
      onEdit: showActions ? () => {
        dispatch('GET_INPUT', { type: 'price', string: product.price, hint: <>How much is a <strong>{product.name}</strong>?</>, why: 'product--price', entity: product, selectAll: true })
      } : undefined
    },
    doStockControl && {
      name: product.stock.toLocaleString(),
      onEdit: showActions ? () => {
        dispatch('STOCK', { why: 'ProductListItem', entity: product, stock: product.stock, stockNotify: product.stockNotify })
      } : undefined
    }
  ]
    .filter(_ => _)

  return (
    <ListItem
      key={`${prefix}-${product.id}`}
      id={product.id}
      InlineIcon={!selectMode && dashboardIcons.product}
      icon={selectMode ? (selectedIds.find(pp => pp.id === product.id) ? icons.generic.check : icons.generic.uncheck) : undefined}
      selected={product.id === currentProductId}
      actions={showActions ? ['copy', 'delete'] : undefined}
      onAction={(_, action) => onAction(action, product, masterProductCategory)}
      onChoose={(() => {
        if (selectMode) {
          return () => {
            if (selectedIds.find(s => s.id === product.id)) {
              selectedIds = selectedIds.filter(s => s.id !== product.id)
            } else {
              selectedIds.push({
                type: 'product',
                id: product.id
              })
            }
            setSelectedIndices([...selectedIds])
          }
        }
        return onChangeCurrentProductId
      })()}
      goTo={canGoToProduct && !selectMode && `/me/products/${product.id}`}
      tags={tags}
      style={{ opacity: ((masterProductCategory ? masterProductCategory.isEnabled : true) && product.forSale) ? 1 : 0.5, color: masterProductCategory ? masterProductCategory.foregroundColor : undefined }}
      doHover={false}
    >
      {product.name}
    </ListItem>
  )
}

export default function ProductsLikeAMenu({
  user,
  productCategories,
  products,
  currentProductId,
  onChangeCurrentProductId,
  allowNone = false,
  showActions = false,
  canGoToProduct = false,
  onProductCategoryEdit,
  onProductCategoryAction,
  onAction,

  masterActions = [],
  onMasterAction,
  onDrag
}) {
  const [selectMode, setSelectMode] = useState()
  let [selectedIds, setSelectedIndices] = useState([])
  const [pcFilter, setPcFilter] = useState({ text: '' })
  const [doStockControl, setDoStockControl] = useState(stockControlLoad())

  const [pcsExpanded, setPcsExpanded] = useState([])

  // const [sortDetails, setSortDetails] = useState({})
  // const updateSortOption = (categoryId) => {
  //   let newSortOption
  //   let newSORT_OPTION
  //   if (sortDetails[categoryId]) {
  //     const sortOptions = ['nameAsc', 'nameDesc', 'priceAsc', 'priceDesc', 'stockAsc', 'stockDesc']
  //     const { sortOption: currentSortOption } = sortDetails[categoryId]
  //     const currentSortOptionsIndex = sortOptions.indexOf(currentSortOption)
  //     const newSortOptionsIndex = (currentSortOptionsIndex + 1) % sortOptions.length

  //     newSortOption = sortOptions[newSortOptionsIndex]
  //     newSORT_OPTION = /name/.test(newSortOption) ? 'name' : /price/.test(newSortOption) ? 'price' : 'stock'

  //   } else {
  //     newSortOption = 'nameDesc'
  //     newSORT_OPTION = 'name'
  //   }

  //   setSortDetails({
  //     ...sortDetails,
  //     [categoryId]: {
  //       sortOption: newSortOption,
  //       SORT_OPTION: newSORT_OPTION
  //     }
  //   })
  // }

  const toggleStockControl = () => {
    setDoStockControl(doStockControl ? false : true)
    stockControlSave(doStockControl ? false : true)
  }

  useEffect(() => {
    const subscriptions = [
      subscribe(
        'GET_INPUT_GOOD',
        async ({ why, string, entity }) => {
          why === 'copy-product-category' && await (async () => {
            const privateKey = string.trim()
            if (!isPrivateKey(privateKey)) {
              dispatch('SHOW_MESSAGE', { message: <p>You didn't enter a private key!</p>, canBeBadded: '' })
              return
            }
            dispatch('LOADING')
            try {
              await window.sticky.internals.trigger('copy-product-category', { privateKey, productCategoryId: entity.id })
            } catch (e) {
              dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
            }
            dispatch('STOP_LOADING')
          })()
          why === 'copy-product' && await (async () => {
            const privateKey = string.trim()
            if (!isPrivateKey(privateKey)) {
              dispatch('SHOW_MESSAGE', { message: <p>You didn't enter a private key!</p>, canBeBadded: '' })
              return
            }
            dispatch('LOADING')
            try {
              const selectedProductIds = selectedIds
                .filter(s => s.type === 'product')
                .map(s => s.id)
              for (let i = 0; i < selectedProductIds.length; i++) {
                const productId = selectedProductIds[i]
                await window.sticky.internals.trigger('copy-product', { privateKey, productId })
              }
            } catch (e) {
              dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
            }
            dispatch('STOP_LOADING')
            setSelectedIndices([])
            setSelectMode(undefined)
          })()
        }
      ),
      subscribe(
        'PRODUCT_CHANGE_TAGS_VAT_GOOD',
        async ({ why, value }) => {
          why === 'ProductsLikeAMenu' && await (async () => {
            dispatch('LOADING')
            const selectedProducts = selectedIds
              .filter(s => s.type === 'product')
              .map(s => products.find(p => p.id === s.id))
            log('[ProductsLikeAMenu]', { why, value, selectedProducts })
            for (let i = 0; i < selectedProducts.length; i++) {
              const product = selectedProducts[i]
              ;[
                ...tags.map(_ => _.id),
                ...product.categories.toArray()
              ]
                .filter(t => t.startsWith('vat--'))
                .forEach(v => {
                  product.categories.delete(v)
                })
              value && product.categories.add(value)
              await window.sticky.products.save(product, ['categories'])
            }
            setSelectMode(undefined)
            setSelectedIndices([])
            dispatch('STOP_LOADING')
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  const legacyProductsNotInACategory = products.filter(p => {
    return productCategories.every(pc => !pc.products.has(p.id))
  })
  const allSelectedProducts = selectedIds.length > 0 && selectedIds.every(s => s.type === 'product')

  const taxBorkedProducts = products.filter(p => {
    const howManyTaxTags = p.categories.toArray().filter(t => t.startsWith('vat--')).length
    const isBorked = howManyTaxTags > 1
    return isBorked
  })

  let hasPulsed = false
  const abstractedPlProps = {
    currentProductId,
    canGoToProduct,
    showActions,
    onAction,
    onChangeCurrentProductId,
    selectMode,
    selectedIds,
    setSelectedIndices,
    doStockControl
  }

  const magicAllConnectionsMethods = []
  user.connections.forEach(({ id: connectionId }) => {
    const foundConnection = user.getConnection(connectionId)
    foundConnection.methods
      .filter(([methodId, methodName, methodUiPlaces]) => methodUiPlaces.includes('products'))
      .forEach(method => magicAllConnectionsMethods.push({
        connection: foundConnection,
        method
      }))
  })
  
  return (
    <StyledComponent className='component--products-like-a-menu'>
      {masterActions.length > 0 && <div className='buttons'>
        {!selectMode && <>
          {masterActions.includes('add') && magicAllConnectionsMethods.map(_ => {
            const [method, methodName] = _.method
            return <Button
              icon={_.connection.logoInverted}
              key={`${_.connection.id}_${method}`}
              color="#FFF"
              backgroundColor={_.connection.color}
              onClick={async () => {
                dispatch('LOADING')
                try {
                  const response = await window.sticky.internals.trigger('public--connections-go', { id: _.connection.id, method }, true)
                  onAction('getAll')
                } catch (err) {
                  window.sticky.applications.blocks.showError(err.message, true)
                } finally {
                  dispatch('STOP_LOADING')
                }
              }}
            >
              {_.connection.name} → {methodName}
            </Button>
          })}
          {masterActions.includes('add') && (
            <Button
              className={productCategories.length === 0 ? 'openbox--pulsing-1' : undefined}
              backgroundColor='#a55eea'
              onClick={() => onMasterAction('add')}
              icon={icons.inverted.add}
              id='NEW_PRODUCT_CATEGORY'
            >
              {_('NEW_PRODUCT_CATEGORY')}
            </Button>
          )}
          <Button
            onClick={() => {
              setSelectMode('select')
              setSelectedIndices([])
            }}
            isSecondary
            title='Select products'
            InlineIcon={dashboardIcons.select}
          >
            Select products
          </Button>
          {masterActions.includes('import') && (
            <Button
              isSecondary
              icon={icons.generic.upload}
              onClick={() => dispatch('PRODUCTS_IMPORT')}
              title='Import'
            />
          )}
        </>}
        {allSelectedProducts && <>
          <Button
            isSecondary
            InlineIcon={dashboardIcons.copy}
            onClick={() => {
              dispatch('GET_INPUT', { why: 'copy-product', hint: 'Enter the private key of the destination dashboard:', string: '', doValidate: false })
            }}
          >
            Copy individual products to dashboard
          </Button>
          <Button
            onClick={() => {
              dispatch('PRODUCT_CHANGE_TAGS_VAT', { why: 'ProductsLikeAMenu' })
            }}
            isSecondary
          >
            Change tax
          </Button>
        </>}
        {selectMode && <>
          <Button
            onClick={() => {
              const newIndices = [
                ...products.map(p => ({ type: 'product', id: p.id }))
              ]
              setSelectedIndices(newIndices)
            }}
            isSecondary
            InlineIcon={dashboardIcons.selectAll}
          >
            Select all products
          </Button>
          <Button
            onClick={() => {
              setSelectMode(undefined)
              setSelectedIndices([])
            }}
            isSecondary
          >
            Cancel
          </Button>
        </>}
        {!selectMode && <>
          {masterActions.includes('search') && (
            <FilterButton
              onChange={setPcFilter}
              turnedOnColor='#a55eea'
              memoryKey='products'
              filterByTags={false}
            />
          )}
          {masterActions.includes('stock-control') && (
            <Switch
              checked={doStockControl}
              onChange={toggleStockControl}
            >
              Edit stock
            </Switch>
          )}
        </>}
      </div>}
      {allowNone && <Box className='product-category'>
        <List>
          <ListItem
            selected={currentProductId === undefined}
            onChoose={() => onChangeCurrentProductId && onChangeCurrentProductId(null)}
            id='none'
            key='none'
            InlineIcon={dashboardIcons.product}
          >
            (None)
          </ListItem>
        </List>
      </Box>}
      {taxBorkedProducts.length > 0 && (<Box className='tax-borked-products'>
        <H1>Tax problems</H1>
        <p>
          These products have multiple internal tax labels. This is usually due to an integration behaving incorrectly.
        </p>
        <p>
          Numbers in <strong>Analytics</strong> will be wrong until you fix each product.
        </p>
        <p>
          To fix a product, choose it from the list, change its "Tax" dropdown to any another item and then change it to the correct item. Choose <strong>Save</strong> then come back to this message.
        </p>
        <p>
          If there are no more tax problems you won't see this message.
        </p>
        <Box>
          <List>
            {taxBorkedProducts.map(p => (
              <ProductListItem
                {...abstractedPlProps}
                prefix='doesnt-matter'
                product={p}
                key={p.id}
              />
            ))}
          </List>
        </Box>
      </Box>)}
      {legacyProductsNotInACategory.length > 0 && <ExpandCollapse
        text='Not in a category'
        className={classnames('product-category', 'is-enabled')}
      >
        <Button
          isSecondary
          onClick={() => onProductCategoryAction(undefined, 'delete')}
          title='Delete all products'
          backgroundColor='#ff3838'
          icon={icons.inverted.delete}
          className='control-tr delete'
        />
        <List>
          {legacyProductsNotInACategory.map(p => (
            <ProductListItem
              {...abstractedPlProps}
              prefix='doesnt-matter'
              product={p}
              key={p.id}
            />
          ))}
        </List>
      </ExpandCollapse>}
      <ExpandCollapseContainer
        draggable
        onDrag={onDrag}
      >
        {productCategories
          .map(pc => {
            const pcMatches = pc.doesMatchFilter(pcFilter.text, false)
            const matchingProducts = pc.products.toArray()
              .map(_ => products.find(__ => __.id === _))
              .filter(_ => _ && _.doesMatchFilter(pcFilter.text, false))

            
            // pcSortOption = !sortDetails[pc.id] ? 'nameAsc' : sortDetails[pc.id].sortOption
            // pcSORT_OPTION = !sortDetails[pc.id] ? 'name' : sortDetails[pc.id].SORT_OPTION

            if (pcMatches || matchingProducts.length > 0) {
              // const tags = []
              // showTags && typeof pc.connection === 'string' && tags.push({
              //   name: user.getConnection(pc.connection).shortName,
              //   color: user.getConnection(pc.connection).color
              // })
              // const sortProducts = {
              //   'nameAsc': () => matchingProducts.sort((_, __) => _.name.localeCompare(__.name)),
              //   'nameDesc': () => matchingProducts.sort((_, __) => __.name.localeCompare(_.name)),
              //   'priceAsc': () => matchingProducts.sort((_, __) => _.price - __.price),
              //   'priceDesc': () => matchingProducts.sort((_, __) => __.price - _.price),
              //   'stockAsc': () => matchingProducts.sort((_, __) => _.stock - __.stock),
              //   'stockDesc': () => matchingProducts.sort((_, __) => __.stock - _.stock)
              // }
              // sortProducts[pcSortOption]()

              let toRender = matchingProducts.map(p => <ProductListItem
                {...abstractedPlProps}
                prefix={pc.id}
                product={p}
                masterProductCategory={pc}
                key={p.id}
              />)
              let willPulse = pc.products.size === 0
              if (hasPulsed) {
                willPulse = false
              }
              if (willPulse) {
                hasPulsed = true
              }

              return (
                <ExpandCollapse
                  text={pc.connection ? <>{pc.name} <strong className='managed'>(Managed)</strong></> : pc.name}
                  key={pc.id}
                  color={pc.foregroundColor}
                  backgroundColor={pc.color}
                  className={classnames('product-category', { 'has-buttons': showActions, 'is-enabled': pc.isEnabled })}
                  isCollapsed={matchingProducts.length === 0 ? false : !pcsExpanded.includes(pc.id)}
                  onToggle={() => {
                    if (pcsExpanded.includes(pc.id)) {
                      setPcsExpanded(
                        pcsExpanded.filter(_ => _ !== pc.id)
                      )
                    } else {
                      setPcsExpanded([
                        ...pcsExpanded,
                        pc.id
                      ])
                    }
                  }}
                >
                  {showActions && <>
                    {/* <Button
                      isSecondary
                      onClick={() => {
                        dispatch('GET_INPUT', { why: 'copy-product-category', hint: 'Enter the private key of the destination dashboard:', string: '', doValidate: false, entity: pc })
                      }}
                      title='Copy to dashboard'
                      InlineIcon={dashboardIcons.copy}
                      className='control-tr copy'
                    /> */}
                    {selectMode && <Button                      
                      isSecondary
                      onClick={() => {
                        selectedIds = selectedIds
                          .filter(_ => !matchingProducts.find(__ => __.id === _.id))
                          .map(_ => ({ type: 'product', id: _.id }))
                        selectedIds = [
                          ...selectedIds,
                          ...matchingProducts.map(_ => ({ type: 'product', id: _.id }))
                        ]
                        setSelectedIndices(selectedIds)
                      }}
                      title='Select all'
                      InlineIcon={dashboardIcons.copy}
                      className='control-tr select-all'
                    >
                      Select all
                    </Button>}
                    <Button
                      isSecondary
                      onClick={() => onProductCategoryEdit(pc)}
                      title='Edit'
                      icon={icons.generic.edit}
                      className='control-tr edit'
                    />
                    <Button
                      isSecondary
                      onClick={() => onProductCategoryAction(pc, 'delete')}
                      title='Delete'
                      backgroundColor='#ff3838'
                      icon={icons.inverted.delete}
                      className='control-tr delete'
                    /> 
                    {/* <Button
                      isSecondary
                      onClick={() => {onProductCategoryAction(pc, 'sort'); updateSortOption(pc.id)}}
                      InlineIcon={/Asc/.test(pcSortOption) ? dashboardIcons.arrowUp : dashboardIcons.arrowDown}
                      className='control-tr sort'
                    >
                      Sort by {pcSORT_OPTION}
                    </Button> */}
                  </>}
                  {toRender.length > 0 && <List emptyText={null}>{toRender}</List>}
                  {showActions && <div className='buttons'>
                    <Button
                      className={willPulse ? 'openbox--pulsing-1' : undefined}
                      backgroundColor='#a55eea'
                      onClick={() => onProductCategoryAction(pc, 'add')}
                      icon={icons.inverted.add}
                    >
                      {_('NEW_PRODUCT')}
                    </Button>
                    <Button
                      isSecondary
                      onClick={() => dispatch('NEW_BASE_PRODUCT', { productCategory: pc, why: 'TabProductsCategoriesRenderChild' })}
                      icon={icons.generic.add}
                      id='NEW_PRODUCT'
                    >
                      Add by template
                    </Button>
                  </div>}
                </ExpandCollapse>
              )
            }
          })
          .filter(e => e)}
      </ExpandCollapseContainer>
    </StyledComponent>
  )
}

ProductsLikeAMenu.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  productCategories: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  currentProductId: PropTypes.string,
  onChangeCurrentProductId: PropTypes.func,
  allowNone: PropTypes.bool,
  showActions: PropTypes.bool,
  canGoToProduct: PropTypes.bool,
  onProductCategoryEdit: PropTypes.func,
  onProductCategoryAction: PropTypes.func,

  masterActions: PropTypes.arrayOf(PropTypes.string),
  onMasterAction: PropTypes.func
}
