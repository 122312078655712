import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, CustomHelmet, Loading, Button, Modal, LinkButton } from '@openbox-app-shared'

import { log, error } from '../../../log'
import beep from '../../../beep'
import dashboardIcons from '../../../icons'
import { dispatch, subscribe } from '../../../redux'
import CardsComponents from './cards'
import UserCircle from '../../../components/UserCircle'

const DO_BEEP_AT_ALL = true
const DO_REFRESH = true
const REFRESH_INTERVAL = 5 * 1000

const Cards = {
  Payment: {
    Component: CardsComponents.Payment,
    doAction: (entity, action) => {
      return new Promise(resolve => {
        action === 'print' && (() => {
          dispatch('TRIGGER', { trigger: 'printPayment', body: { paymentId: entity.id } })
        })()
        action === 'tags' && (() => {
          dispatch(
            'CHOOSE_TAGS',
            {
              why: 'stickyretail--Payment--tags',
              entity,
              tags: entity.tags
            }
          )
        })()
        action === 'done' && (() => {
          entity.newStatusDone = !(entity.newStatusDone || false)
          dispatch('LOADING')
          Cards.Payment.patch(entity, false, ['newStatusDone'])
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
        })()
        action === 'changeFederatedUser' && (() => {
          dispatch('CHOOSE_FEDERATED_USER', { why: 'stickyretail--changeFederatedUser--Payment', entity, canBeClosed: true })
        })()
        action === 'removeFederatedUser' && (() => {
          entity.lastFederatedUserId = null
          dispatch('LOADING')
          Cards.Payment.patch(entity, false, ['lastFederatedUserId'])
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('getCards')
            })
        })()
        action === 'session' && (() => {
          dispatch('LOADING')
          const { sessionId } = entity
          window.sticky.session.get(undefined, false, sessionId)
            .then(session => {
              log('[Card->Payment] (teamMember)', { sessionId, session })
              dispatch('SESSION', { session })
              dispatch('STOP_LOADING')
              resolve()
            })
        })()
        action === 'pay' && (() => {
          entity.sessionPaidAt = Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000)
          dispatch('LOADING')
          Cards.Payment.patch(entity, false, ['sessionPaidAt'])
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
        })()
        action === 'note' && (() => {
          dispatch('GET_INPUT', { type: 'textarea', string: entity.extra || '', selectAll: true, why: 'stickyretail--Payment--extra', entity })
          resolve()
        })()
      })
    },
    patch: (...args) => {
      return window.sticky.pay.save(...args)
    },
    isDone: entity => entity.newStatusDone
  },
  EVENT_TO_DO: {
    Component: CardsComponents.EVENT_TO_DO,
    patch: (...args) => {
      return window.sticky.session.events.save(...args)
    },
    doAction: (entity, action, ...args) => {
      return new Promise(resolve => {
        action === 'done' && (() => {
          entity.customData.writeTo('newStatusDone', true)
          dispatch('LOADING')
          Cards.EVENT_TO_DO.patch(entity)
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
        })()
        action === 'changeFederatedUser' && (() => {
          dispatch('CHOOSE_FEDERATED_USER', { why: 'stickyretail--changeFederatedUser--EVENT_TO_DO', entity, canBeClosed: true })
        })()
        action === 'removeFederatedUser' && (() => {
          entity.federatedUserId = null
          dispatch('LOADING')
          Cards.EVENT_TO_DO.patch(entity)
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('getCards')
            })
        })()
        action === 'session' && (() => {
          dispatch('LOADING')
          const { sessionId } = entity
          window.sticky.session.get(undefined, false, sessionId)
            .then(session => {
              log('[Card->EVENT_TO_DO] (teamMember)', { sessionId, session })
              dispatch('SESSION', { session })
              dispatch('STOP_LOADING')
              resolve()
            })
        })()
        action === 'event' && (() => {
          dispatch('LOADING')
          const [eventId] = args
          window.sticky.session.events.get(eventId)
            .then(event => {
              log('[Card->EVENT_TO_DO] (moreEventId)', { event })
              dispatch('EVENT', { event })
              dispatch('STOP_LOADING')
              resolve()
            })
        })()
      })
    },
    isDone: entity => entity.customData.readFrom('newStatusDone') === true
  }
}

const StyledRoute = styled.div`
  .to-dos {
    margin: 0 1rem 0 1rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (min-width: 50rem) {
    .to-dos {
      > * {
        width: 23.5rem;
        margin-right: 1rem;
      }
    }
  }
  .controls {
    width: 100%;
    padding: 1rem 1rem 0 1rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
    > span, > strong {
      height: 2.5rem;
      line-height: 2.5rem;
      color: white;
      &.big {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        color: black;
        background-color: yellow;
        font-size: 150%;
        border-radius: 6px;
        box-shadow: 0 2px 5px 0 rgb(60 66 87 / 12%), 0 1px 1px 0 rgb(0 0 0 / 12%);
      }
    }
  }
  .choose-thing img {
    width: 0.9375rem;
  }
  .beep-yes-no {
    p {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .buttons {
      text-align: center;
      > * {
        display: inline-block;
        vertical-align: top;
      }
      > * + * {
        margin-left: 1rem;
      }
    }
  }
`

export default class Route extends Component {
  constructor() {
    super()
    const url = new URL(window.location.href)
    const overrideApplicationId = url.searchParams.get('overrideApplicationId')
    this.state = {
      howManyCardsBefore: undefined,
      showingDummyModal: DO_BEEP_AT_ALL,
      filteringByFederatedUser: false,
      overrideApplicationId,
      filterByProductTags: window.sticky.newPatchableSet([])
    }
    this.isWorking = false
    this.getCards = this.getCards.bind(this)
  }

  async getAllPayments(finalState) {
    const { filterByProductTags } = finalState
    const { userPreferences } = this.props
    const parts = [true].includes(userPreferences.toDoShowOnlyUnpaidGATEWAY_LATER) ? ['gateway=GATEWAY_LATER'] : ['newStatusDone=!!!null', 'sessionPaidAt=any']
    finalState.filteringByApplication && parts.push(`applicationId=${finalState.filteringByApplication.id}`)
    finalState.overrideApplicationId && parts.push(`applicationId=${finalState.overrideApplicationId}`)
    finalState.filteringByThing && parts.push(`thingId=${finalState.filteringByThing.id}`)
    finalState.filteringByFederatedUser && parts.push(`lastFederatedUserId=${finalState.currentFederatedUser.id}`)
    window.sticky.pay.setQuery(parts.join('&'))
    let toReturn = [...(await window.sticky.pay.getAll(true))].reverse()
    if (filterByProductTags.hasAny) {
      toReturn = toReturn.filter(_ => _.cart.get().every(ci => ci.product ? ci.product.tags.toArray().some(pTag => filterByProductTags.has(pTag)) : true))
    }
    return toReturn
  }
  async getAllEvents(finalState) {
    const queryName = (finalState.filteringByApplication || finalState.filteringByThing) ? 'type-application-thing' : 'type'
    const query = {
      type: 'TO_DO',
      applicationId: finalState.filteringByApplication ? finalState.filteringByApplication.id : undefined,
      thingId: finalState.filteringByThing ? finalState.filteringByThing.id : undefined,
      federatedUserId: finalState.filteringByFederatedUser ? finalState.currentFederatedUser.id : undefined
    }
    return window.sticky.session.events.getAll(queryName, query, true)
  }

  async getCardsData(thisState) {
    const allPayments = await this.getAllPayments(thisState)
    const allEvents = await this.getAllEvents(thisState)
    return {
      allPayments,
      allEvents
    }
  }

  async getCards(veryFreshState) {
    log('[Route-view-stickyretail] [getCards] 1', { veryFreshState, thisIsWorking: this.isWorking })
    const onDone = () => {
      this.isWorking = false
    }
    if (this.isWorking) {
      veryFreshState && this.setState(veryFreshState)
      return
    }
    this.isWorking = true

    try {
      const thisState = { ...this.state, ...(veryFreshState || {}) }
      const { allPayments, allEvents } = await this.getCardsData(thisState)

      log('[Route-view-stickyretail] [getCards] 2', { allPayments, allEvents })

      const cards = [
        ...allEvents.map(entity => ({
          holder: Cards.EVENT_TO_DO,
          entity,
          time: entity.time,
          id: entity.id
        })),
        ...allPayments.map(entity => ({
          holder: Cards.Payment,
          entity,
          time: entity.time,
          id: entity.id
        }))
      ]
      const howManyCardsNow = cards.length

      const { howManyCardsBefore } = this.state
      const isThereANewCard = typeof howManyCardsBefore === 'number' && howManyCardsNow > howManyCardsBefore
      if (isThereANewCard) {
        this.newCard()
      }

      const federatedUsers = await window.sticky.users.federated.getAll()
      const things = await window.sticky.things.getAll()
      const applications = await window.sticky.applications.getAll()
      this.setState(
        {
          federatedUsers,
          cards,
          things,
          applications,
          howManyCardsBefore: howManyCardsNow,
          ...veryFreshState
        },
        onDone
      )
    } catch (e) {
      error('[Route-view-stickyretail] [getCards]', e)
    } finally {
      onDone()
    }
  }

  newCard() {
    this.beeper.beep()
  }

  async componentDidMount() {
    document.querySelector('main').style.maxWidth = 'none'
    document.querySelector('.component--navbar').style.display = 'none'

    const { user, userPreferences } = this.props

    if (!user.federatedUserCan('live-payments')) {
      return window.sticky.applications.blocks.showError('You do not have permission to see live payments.')
    }

    this.beeper = beep(userPreferences.toDoNewCardSound || 'beep')
    await this.getCards()
    this.refreshTimer = DO_REFRESH && setInterval(
      this.getCards,
      REFRESH_INTERVAL
    )
    this.subscriptions = [
      subscribe(
        'CHOOSE_FEDERATED_USER_GOOD',
        ({ why, entity, federatedUser }) => {
          why === 'stickyretail' && (() => {
            const state = {
              currentFederatedUser: federatedUser
            }
            this.setState(state)
          })()
          why === 'stickyretail--changeFederatedUser--Payment' && (() => {
            entity.lastFederatedUserId = federatedUser.id
            dispatch('LOADING')
            window.sticky.pay.save(entity, false)
              .then(() => {
                dispatch('STOP_LOADING')
                this.forceUpdate()
              })
          })()
          why === 'stickyretail--changeFederatedUser--EVENT_TO_DO' && (() => {
            entity.federatedUserId = federatedUser.id
            dispatch('LOADING')
            window.sticky.session.events.save(entity)
              .then(() => {
                dispatch('STOP_LOADING')
                this.forceUpdate()
              })
          })()
        }
      ),
      subscribe(
        'CHOOSE_APPLICATIONS_GOOD',
        ({ why, applications }) => {
          why === 'stickyretail' && (() => {
            const state = {
              filteringByApplication: applications[0]
            }
            this.getCards(state)
          })()
        }
      ),
      subscribe(
        'CHOOSE_THING_GOOD',
        ({ thing: filteringByThing, why }) => {
          why === 'stickyretail' && (() => {
            this.isWorking = false
            this.getCards({ filteringByThing })
          })()
        }
      ),
      subscribe(
        'SURE_DELETE_GOOD',
        ({ why }) => {
          why === 'stickyretail--delete-events--TO_DO' && (() => {
            const { filteringByApplication, filteringByThing } = this.state
            dispatch(
              'TRIGGER',
              {
                trigger: 'delete-events',
                body: {
                  type: 'TO_DO',
                  applicationId: filteringByApplication ? filteringByApplication.id : undefined,
                  thingId: filteringByThing ? filteringByThing.id : undefined
                },
                showMessage: false
              }
            )
            this.getCards()
          })()
        }
      ),
      subscribe(
        'TRIGGER_GOOD',
        ({ trigger }) => {
          trigger === 'delete-events' && (() => {
            this.getCards()
          })()
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, entity, string }) => {
          why === 'stickyretail--Payment--extra' && (async () => {
            this.isWorking = true
            entity.extra = string
            Cards.Payment.patch(entity, false, ['extra'])
              .then(() => {
                this.isWorking = false
                this.forceUpdate()
              })
          })()
        }
      ),
      subscribe(
        'CHOOSE_TAGS_GOOD',
        ({ why, entity, tags }) => {
          why === 'stickyretail--Payment--tags' && (async () => {
            this.isWorking = true
            entity.tags = tags
            Cards.Payment.patch(entity, false, ['tags'])
              .then(() => {
                this.isWorking = false
                this.forceUpdate()
              })
          })()
          why === 'stickyretail--product-tags' && (() => {
            this.getCards({
              filterByProductTags: tags
            })
          })()
        }
      )
    ]
  }

  async componentWillUnmount() {
    this.beeper.unbeep()
    clearInterval(this.refreshTimer)
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  switchUser (canBeClosed) {
    dispatch('CHOOSE_FEDERATED_USER', { why: 'stickyretail', canBeClosed })
  }

  render() {
    const { user, userPreferences, autoUi } = this.props
    const {
      cards,
      things,
      applications,
      filteringByApplication,
      filteringByThing,
      filteringByFederatedUser,
      filterByProductTags,
      showingDummyModal,
      federatedUsers,
      currentFederatedUser,
      overrideApplicationId
    } = this.state
    const cardRdic = {
      user,
      federatedUsers,
      things,
      applications
    }
    log('[Route-view-stickyretail] [render]', { cards, cardRdic })
    let cardsNotDone = Array.isArray(cards) ? cards.filter(p => !p.holder.isDone(p.entity)) : []
    let cardsDone = Array.isArray(cards) ? cards.filter(p => p.holder.isDone(p.entity)) : []

    if ([false, undefined].includes(userPreferences.toDoEVENT_TO_DOFirst)) {
      cardsNotDone = cardsNotDone
        .sort((a, b) => {
          return a.time - b.time
        })
      cardsDone = cardsDone
        .sort((a, b) => {
          return b.time - a.time
        })
    }

    // const filteringByThingIndex = filteringByThing ? things.indexOf(filteringByThing) : undefined

    const cardMaps = [
      ...cardsNotDone,
      ...cardsDone
    ]
      .map(p => {
        const { Component, doAction } = p.holder
        return (
          <Component
            key={p.id}
            user={user}
            userPreferences={userPreferences}
            rdic={cardRdic}
            isDone={p.holder.isDone(p.entity)}
            currentFederatedUser={currentFederatedUser}
            onAction={(...args) => doAction(p.entity, ...args)
              .then((whatToDo) => {
                whatToDo === 'forceUpdate' && this.forceUpdate()
                whatToDo === 'getCards' && this.getCards()
              })
            }
            entity={p.entity}
          />
        )
      })

    return (
      <StyledRoute>
        <CustomHelmet
          title='Live payments'
        />
        <div className='controls'>
          {autoUi.includes('backToYeetYeet') && <LinkButton
            to='/sticky-pay'
            sameTab
            backgroundColor='transparent'
          >
            ← Exit
          </LinkButton>}
          {currentFederatedUser && (
            <UserCircle fixedWidthHeight={2.5} onClick={() => this.switchUser(true)} name={currentFederatedUser.name} color={currentFederatedUser.color} photoUrl={currentFederatedUser.photoUrl} />
          )}
          {[true, undefined].includes(userPreferences.toDoFilterByApplication) && applications && applications.length > 0 && (
            <Button
              icon={filteringByApplication && filteringByApplication.baseIcon}
              InlineIcon={!filteringByApplication ? dashboardIcons.application : undefined}
              onClick={() => {
                dispatch('CHOOSE_APPLICATIONS', { why: 'stickyretail', allowNone: '(All)' })
              }}
              disabled={overrideApplicationId}
            >
              {overrideApplicationId && 'Filtering by specific flow'}
              {overrideApplicationId ? '' : (filteringByApplication ? filteringByApplication.name : 'Filter by flow')}
            </Button>
          )}
          {/* {filteringByThing && (
            <Button
              title='Previous sticky'
              disabled={filteringByThingIndex === 0}
              inlineIconString={window.sticky.internals.icons.get('arrowLeft')}
              onClick={() => this.getCards({ filteringByThing: things[filteringByThingIndex - 1] })}
            />
          )} */}
          {[true, undefined].includes(userPreferences.toDoFilterByApplication) && <Button
            className='choose-thing'
            icon={filteringByThing && filteringByThing.designUrl}
            InlineIcon={!filteringByThing ? dashboardIcons.thing : undefined}
            onClick={() => {
              this.isWorking = true
              ;(async () => {
                dispatch('LOADING')
                const cardsData = await this.getCardsData({ ...this.state, filteringByThing: undefined })
                const highlightedThingIds = Array.from(new Set([
                  ...cardsData.allEvents.filter(_ => _.customData.readFrom('newStatusDone') !== true).map(_ => _.thingId),
                  ...cardsData.allPayments.filter(_ => _.newStatusDone !== true).map(_ => _.thingId)
                ]))
                dispatch('STOP_LOADING')
                dispatch('CHOOSE_THING', { why: 'stickyretail', allowNone: '(All)', highlightedThingIds })
              })()
            }}
          >
            {filteringByThing ? filteringByThing.name : 'Filter by sticky'}
          </Button>}
          {/* {filteringByThing && (
            <Button
              title='Next sticky'
              disabled={filteringByThingIndex >= things.length - 1}
              inlineIconString={window.sticky.internals.icons.get('arrowRight')}
              onClick={() => this.getCards({ filteringByThing: things[filteringByThingIndex + 1] })}
            />
          )} */}
          <Button
            InlineIcon={dashboardIcons.product}
            onClick={() => dispatch(
              'CHOOSE_TAGS',
              {
                why: 'stickyretail--product-tags',
                tags: filterByProductTags.clone()
              }
            )}
          >
            {!filterByProductTags.hasAny ? 'Filter by product labels' : `Filtering by ${filterByProductTags.size} label${filterByProductTags.size !== 1 ? 's' : ''}`}
          </Button>
          {[true, undefined].includes(userPreferences.toDoFilterByApplication) && <Button
            InlineIcon={dashboardIcons.dotDotDot}
            listItems={
              [
                currentFederatedUser && {
                  id: 'filteringByFederatedUser',
                  name: 'Show only mine',
                  icon: filteringByFederatedUser ? icons.generic.check : icons.generic.uncheck,
                  onChoose: () => {
                    this.getCards({ filteringByFederatedUser: !filteringByFederatedUser })
                  }
                },
                {
                  id: 'delete-events',
                  name: 'Delete all to dos',
                  InlineIcon: dashboardIcons.void,
                  onChoose: () => {
                    dispatch('SURE_DELETE', { hint: 'Are you sure?', why: 'stickyretail--delete-events--TO_DO' })
                  }
                }
              ]
                .filter(e => e)
            }
            listItemsWidth={218}
          />}
          {cards && cardsNotDone.length > 0 && <><strong className='big openbox--blinking'>{cardsNotDone.length} to do</strong></>}
        </div>
        {showingDummyModal && (
          <Modal
            className='beep-yes-no'
            canBeClosed={false}
          >
            <p>Beep when there's a new card?</p>
            <div className='buttons'>
              <Button
                icon={icons.inverted.check}
                onClick={() => {
                  this.beeper.start()
                  this.setState({
                    showingDummyModal: false
                  })
                  federatedUsers && federatedUsers.length > 0 && this.switchUser()
                }}
              >
                Beep!
              </Button>
              <Button
                isSecondary
                onClick={() => {
                  this.setState({
                    showingDummyModal: false
                  })
                  federatedUsers && federatedUsers.length > 0 && this.switchUser()
                }}
              >
                No
              </Button>
            </div>
          </Modal>
        )}
        <div className='to-dos'>
          {!cards && <Loading />}
          {cards && cardMaps}
        </div>
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
