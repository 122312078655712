import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, ApplicationBlocks, Banner, Button } from '@openbox-app-shared'
import Box from '../../components/Box'
import ApplePay from '../../components/ApplePay'
import _ from '../../_'
import dashboardIcons from '../../icons'
import { dispatch } from '../../redux'
import { log } from '../../log'

const StyledView = styled.div`
  @media only screen and (min-width: 960px) {
    .grid {
      display: grid;
      grid-gap: 1rem;
    }
    .grid.grid-1 {
      max-width: 480px;
    }
    .grid.grid-2 {
      grid-template-columns: 1fr 1fr;
      > * {
        width: 100%;
      }
    }
  }
  .component--box {
    .component--banner {
      margin-top: 1rem;
      margin-bottom: 0;
      p {
        white-space: break-spaces;
      }
    }
    .component--banner + .component--details {
      margin-top: 1rem;
    }
    > img {
      display: block;
      height: 4rem;
    }
    > div > p {
      font-size: 90%;
      line-height: 1.2rem;
    }
    > div > p + p {
      margin-top: 0.5rem;
    }
  }
  > * + * {
    margin-top: 1rem;
  }
  .component--box.gateway {
    position: relative;
    > img + p {
      margin-top: 1rem;
    }
    > p {
      font-size: 0.8rem;
      line-height: 1.2rem;
      text-align: justify;
      white-space: break-spaces;
    }
  }
  .component--application-blocks {
    max-width: 480px;
    margin-bottom: 0 !important;
    > *:last-child {
      margin-bottom: 0 !important;
    }
  }
  .buttons-down {
    .component--button {
      display: block;
    }
    .component--button + .component--button {
      margin-top: 1rem;
    }
  }
`

function spruceInvalidReasonPartner (input, partner) {
  let output = input
  output = output.replaceAll('{partnerName}', partner.name)
  return output
}

export default function StateView ({ user, entity, triggerProps, gateway, setView, onDisconnect }) {
  const [isApplePayExpanded, setIsApplePayExpanded] = useState(false)
  const isInvalid = gateway.settings.isInvalid(entity)
  log('[ChooseGateway] [StateView]', { user, entity, triggerProps, gateway, setView, onDisconnect })
  const hasAnySettings = gateway.settings.get(entity).some(e => e[3])

  const gatewayDoBrand = gateway.isPartnerBrandable && user.partnerCan('brand-gateway')
  const gatewayLogoUrl = gatewayDoBrand ? user.partner.logoUrl : (gateway.logo || gateway.icon)
  const gatewayName = gatewayDoBrand ? user.partner.name : gateway.name

  const onboarding = {
    trigger: gateway.onboarding.trigger,
    applicationBlocks: isInvalid && gateway.onboarding.applicationBlocks && gateway.onboarding.applicationBlocks({ user }),
    allApplicationBlocks: gateway.onboarding.allApplicationBlocks && gateway.onboarding.allApplicationBlocks({ user }),
    color: gateway.onboarding.color || '#6C7A89'
  }
  const settingsSummary = gateway.settings.getSummary && gateway.settings.getSummary(entity)

  const someAbs = (onboarding.applicationBlocks || onboarding.allApplicationBlocks)
  return (
    <StyledView>
      <div className={`grid grid-${someAbs ? 2 : 1}`}>
        {someAbs && (
          <Box style={{ border: `4px solid ${onboarding.color}`, borderRadius: '8px' }}>
            {onboarding.applicationBlocks && <ApplicationBlocks
              applicationBlocks={onboarding.applicationBlocks}
            />}
            {onboarding.allApplicationBlocks && <ApplicationBlocks
              applicationBlocks={onboarding.allApplicationBlocks}
            />}
          </Box>
        )}
        <div>
          <Box className='gateway'>
            {gateway.id !== 'GATEWAY_NOOP' && <div className='buttons-up'>
              {isInvalid && onboarding.trigger && (
                <>
                  <Button
                    isSecondary isThin
                    onClick={async () => {
                      const values = await window.sticky.applications.blocks.renderInlineEventsButton(
                        gateway.onboarding.trigger.applicationBlocks({ user }),
                        'Activate'
                      )
                      values && dispatch('TRIGGER', { trigger: 'gateway--onboardTrigger', body: { id: gateway.id, values: { ...values, ...triggerProps } } })
                    }}
                    icon={icons.generic.upload}
                  >
                    {onboarding.trigger.name}
                  </Button>
                </>
              )}
              {hasAnySettings && gateway.settings.isEditable(user) && <Button
                onClick={() => setView('StateEdit')}
                isSecondary isThin
                icon={icons.generic.settings}
              >
                {_('SETTINGS')}
              </Button>}
              {user === entity && gateway.settings.isApplePayable && (
                <Button
                  isSecondary isThin
                  onClick={() => setIsApplePayExpanded(true)}
                >
                  Apple Pay settings
                </Button>
              )}
              {isApplePayExpanded && (
                <ApplePay
                  user={user}
                  onBad={() => setIsApplePayExpanded(false)}
                />
              )}
              {gateway.id !== 'GATEWAY_NOOP' && <Button
                isSecondary isThin
                onClick={() => onDisconnect(true)}
                InlineIcon={dashboardIcons.void}
                className='disconnect'
                backgroundColor='#ff3838'
                color='white'
              >
                Disconnect
              </Button>}
            </div>}
            {gateway.id !== 'GATEWAY_NOOP' && <img src={gatewayLogoUrl} alt={gatewayName} />}
            {settingsSummary && <p dangerouslySetInnerHTML={{ __html: settingsSummary }} />}
            {isInvalid && (
              <Banner>
                {!gateway.settings.invalidReasons && <p>
                  <strong>You're not ready to take payments yet.</strong>
                </p>}
                {user.partner && gateway.settings.invalidReasons && gateway.settings.invalidReasons.partner && <p>{spruceInvalidReasonPartner(gateway.settings.invalidReasons.partner, user.partner)}</p>}
                {!user.partner && gateway.settings.invalidReasons && gateway.settings.invalidReasons.generic && <p>{gateway.settings.invalidReasons.generic}</p>}
              </Banner>
            )}
            <div className='buttons-down'>
              {gateway.id === 'GATEWAY_NOOP' && (
                <>
                  <Button
                    onClick={() => onDisconnect(false)}
                    className='openbox--pulsing-2'
                  >
                    Choose a payment provider →
                  </Button>
                  <Button
                    onClick={() => dispatch('TRIGGER', { trigger: 'mungeAllApplicationsGateway--test' })}
                    isSecondary isThin
                    style={{ opacity: '0.25' }}
                  >
                    Internal demo (SIM)
                  </Button>
                  <Button
                    onClick={() => dispatch('TRIGGER', { trigger: 'mungeAllApplicationsGateway--live-worldpay' })}
                    isSecondary isThin
                    style={{ opacity: '0.25' }}
                  >
                    Internal demo (LIVE / WP / MOTO)
                  </Button>
                  {/* <Button
                    onClick={() => dispatch('TRIGGER', { trigger: 'mungeAllApplicationsGateway--live-cashflows' })}
                    isSecondary isThin
                    style={{ opacity: '0.25' }}
                  >
                    Internal demo (LIVE / CF / NO MOTO)
                  </Button> */}
                </>
              )}
            </div>
          </Box>
        </div>
      </div>
    </StyledView>
  )
}
StateView.propTypes = {
  user: PropTypes.object,
  entity: PropTypes.object.isRequired,
  triggerProps: PropTypes.object,
  gateway: PropTypes.object,
  setView: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
