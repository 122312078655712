import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Redirect, Modal } from '@openbox-app-shared'

import { log } from '../../log'
import { dispatch, subscribe } from '../../redux'
import ChooseApplicationBaseOrBaseSet from '../ChooseApplicationBaseOrBaseSet'
import ApplicationChooser from '../ApplicationChooser'

import H1 from '../H1'

import MustTypeModal from '../modals/MustType'
import SureDeleteModal from '../modals/SureDelete'

import InputModal from '../modals/Input'
import PartnerModal from '../modals/Partner'
import DetailsModal from '../modals/Details'
import EnterPriceModal from '../modals/EnterPrice'

import PAY_GO_TO from './actions/PAY_GO_TO'
import LOADING from './actions/LOADING'
import STOP_LOADING from './actions/STOP_LOADING'
import CHOOSE_FEDERATED_USER from './actions/CHOOSE_FEDERATED_USER'
import APPLICATION_BLOCK from './actions/APPLICATION_BLOCK'
import OPERATOR_VIEW_SETTINGS from './actions/OPERATOR_VIEW_SETTINGS'
import PAY_WAIT_ORIGIN from './actions/PAY_WAIT_ORIGIN'
import PAYMENT_REFUND from './actions/PAYMENT_REFUND'
import CREATE_PAYMENT from './actions/CREATE_PAYMENT'
import CREATE_SHORT_LINK from './actions/CREATE_SHORT_LINK'
import TRIGGER from './actions/TRIGGER'
import CHOOSE_THING from './actions/CHOOSE_THING'
import NEW_BASE_PRODUCT from './actions/NEW_BASE_PRODUCT'
import PRODUCT_CHANGE_TAGS_VAT from './actions/PRODUCT_CHANGE_TAGS_VAT'
import PRODUCTS_IMPORT from './actions/PRODUCTS_IMPORT'
import USER_EDIT from './actions/USER_EDIT'

import SHOW_MESSAGE from './actions/SHOW_MESSAGE'
import LETS_GET_THIS_BREAD from './actions/LETS_GET_THIS_BREAD'
import APPLICATION_FOR_PAY from './actions/APPLICATION_FOR_PAY'
import FRAME from './actions/FRAME'
import SESSION from './actions/SESSION'
import SESSION_CLOSE from './actions/SESSION_CLOSE'
import SESSION_BECOME from './actions/SESSION_BECOME'
import LOCATION from './actions/LOCATION'
import CHOOSE_TAGS from './actions/CHOOSE_TAGS'
import EVENT from './actions/EVENT'
import STOCK from './actions/STOCK'
import ONBOARDING from './actions/ONBOARDING'

const ABSTRACTED = [
  ['PAY_GO_TO', PAY_GO_TO],
  ['USER_EDIT', USER_EDIT],
  ['LOADING', LOADING],
  ['STOP_LOADING', STOP_LOADING],
  ['CHOOSE_FEDERATED_USER', CHOOSE_FEDERATED_USER],
  ['APPLICATION_BLOCK', APPLICATION_BLOCK],
  ['OPERATOR_VIEW_SETTINGS', OPERATOR_VIEW_SETTINGS],
  ['PAY_WAIT_ORIGIN', PAY_WAIT_ORIGIN],
  ['PAYMENT_REFUND', PAYMENT_REFUND],
  ['TRIGGER', TRIGGER],
  ['NEW_BASE_PRODUCT', NEW_BASE_PRODUCT],
  ['PRODUCT_CHANGE_TAGS_VAT', PRODUCT_CHANGE_TAGS_VAT],
  ['PRODUCTS_IMPORT', PRODUCTS_IMPORT],
  ['SHOW_MESSAGE', SHOW_MESSAGE],
  ['LETS_GET_THIS_BREAD', LETS_GET_THIS_BREAD],
  ['APPLICATION_FOR_PAY', APPLICATION_FOR_PAY],
  ['FRAME', FRAME],
  ['EVENT', EVENT],
  ['STOCK', STOCK],
  ['ONBOARDING', ONBOARDING],
  ['SESSION', SESSION],
  ['SESSION_CLOSE', SESSION_CLOSE],
  ['SESSION_BECOME', SESSION_BECOME],
  ['CHOOSE_THING', CHOOSE_THING],
  ['LOCATION', LOCATION],
  ['CHOOSE_TAGS', CHOOSE_TAGS],
  ['CREATE_PAYMENT', CREATE_PAYMENT],
  ['CREATE_SHORT_LINK', CREATE_SHORT_LINK]
]

const StyledReduxHandler = styled.div`
  .choose-application-base-or-base-set {
    width: 100%;
    max-width: 640px;
    .component--side-chooser {
      margin-top: 1rem;
    }
  }
  .choose-entity {
    width: calc(100% - 1rem);
    max-width: 29rem;
    max-height: calc(100% - 1rem);
    .component--h1 {
      padding: 0 1rem 1rem 1rem;
      text-align: center;
    }
    .component--input.search {
      max-width: 16rem;
      margin: 0 auto 0.75rem auto;
    }
    .component--list {
      margin-top: 2rem;
    }
    .component--button-grid {
      text-align: center;
      > * {
        font-size: 100%;
        strong {
          line-height: 18px;
          min-height: auto;
        }
      }
    }
    &.grid {
      background-color: #1a1f35;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 1rem;
      .component--h1 {
        color: white;
      }
    }
  }
`

export let state = {}

export class ReduxHandler extends PureComponent {
  constructor () {
    super()
    this.state = {}
    this.setState = this.setState.bind(this)
    this.blinking = []
    this.onChosenApplications = this.onChosenApplications.bind(this)
  }

  blink (id) {
    log('[ReduxHandler] [blink] 1', { id })
    this.blinking.push(id)
    this.blinkTimeout = setTimeout(
      () => {
        const element = document.getElementById(id)
        if (element) {
          log('[App] [blink] 2 good', { id })
          element.classList.add('openbox--blinking')
        } else {
          log('[App] [blink] 3 bad', { id })
        }
      },
      500
    )
  }

  async setState (s = {}) {
    state = {
      ...state,
      ...s
    }
    await new Promise((resolve) => this.forceUpdate(resolve))
    if (state.redirect) this.setState({ redirect: undefined })
  }

  unblink (id) {
    log('[ReduxHandler] [unblink] 1', { id })
    const element = document.getElementById(id)
    element && element.classList.remove('openbox--blinking')
  }

  unblinkAll () {
    clearTimeout(this.blinkTimeout)
    this.blinking.forEach(id => {
      const element = document.getElementById(id)
      element && element.classList.remove('openbox--blinking')
    })
    this.blinking = []
  }

  async refresh () {
    let user
    const { entities = {} } = this.state
    try {
      user = await window.sticky.users.getMe()
      entities.things = await window.sticky.things.getAll()
      entities.applications = await window.sticky.applications.getAll()
      entities.federatedUsers = await window.sticky.users.federated.getAll()
      entities.applicationBlocks = await window.sticky.applications.blocks.getAll(user)
      this.setState({
        entities,
        user
      })
      log('[ReduxHandler] all good!', state)
    } catch (e) {
      log('[ReduxHandler] something failed but its okay - probably no user', e)
    }
    return { user, entities }
  }

  async componentDidMount () {
    this.subscriptions = [
      subscribe('ONBOARDING_GOOD', ({ maybeRedirectTo }) => {
        ;(async () => {
          user.patch({ onboarded: true })
          window.sticky.users.save(undefined, ['onboarded'])
          maybeRedirectTo && dispatch('REDIRECT', { to: maybeRedirectTo })
        })()
      }),
      subscribe(
        'REDIRECT',
        ({ to, cleanSlate, reload }) => {
          log('[ReduxHandler] [subscribe->REDIRECT]', { to, cleanSlate, reload })
          const char = window.location.search.startsWith('?') ? '&' : '?'
          const redirect = !to.startsWith('?') ? to : `${window.location.pathname}${window.location.search}${char}${to.substring(1)}`
          if (reload) {
            window.location = redirect
          } else {
            const newState = {
              redirect,
              redirectCleanSlate: !to.startsWith('?') ? cleanSlate : true
            }
            log('[ReduxHandler] [subscribe->REDIRECT] newState', newState)
            this.setState(
              newState,
              () => {
                this.setState({
                  redirect: undefined
                })
              }
            )
          }
        }
      )
    ]

    const { user, entities } = await this.refresh()

    this.subscriptions = [
      ...this.subscriptions,
      subscribe(
        'REFRESH_REDUX',
        () => {
          log('[ReduxHandler] [subscribe->REFRESH_REDUX]')
          this.refresh()
        }
      ),
      subscribe(
        'BLINK',
        ({ ids }) => {
          log('[ReduxHandler] [subscribe->BLINK]', { ids })
          this.unblinkAll()
          ids.forEach(id => this.blink(id))
        }
      ),
      subscribe(
        'UNBLINK',
        ({ ids }) => {
          log('[ReduxHandler] [subscribe->UNBLINK]', { ids })
          Array.isArray(ids) && ids.forEach(id => this.unblink(id))
          !Array.isArray(ids) && this.unblinkAll()
        }
      ),
      subscribe(
        'DETAILSS',
        ({ detailss, canEdit = true }) => {
          log('[ReduxHandler] [subscribe->DETAILSS]', { detailss, canEdit })
          this.setState({
            detailss: {
              detailss,
              canEdit
            }
          })
        }
      ),
      subscribe(
        'SHOW_MESSAGE_GOOD',
        ({ why }) => {
          why === 'change-plan' && window.location.reload()
        }
      ),
      subscribe('PARTNER_UPDATE', ({ partner }) => this.setState({ partnerUpdating: partner })),
      subscribe(
        'PARTNER_DELETE',
        ({ partner }) => {
          log('[ReduxHandler] [subscribe->PARTNER_DELETE]', { partner })
          this.setState({
            SURE_DELETE: {
              why: 'partner',
              entity: partner
            },
          })
        }
      ),

      subscribe(
        'APPLICATION_BASE_SET_DELETE',
        ({ entity }) => {
          log('[ReduxHandler] [subscribe->APPLICATION_BASE_SET_DELETE]', { entity })
          this.setState({
            SURE_DELETE: {
              why: 'APPLICATION_BASE_SET_DELETE',
              entity
            },
          })
        }
      ),

      subscribe(
        'APPLICATION_BASE_DELETE',
        ({ entity }) => {
          log('[ReduxHandler] [subscribe->APPLICATION_BASE_DELETE]', { entity })
          this.setState({
            SURE_DELETE: {
              why: 'APPLICATION_BASE_DELETE',
              entity
            },
          })
        }
      ),
      subscribe(
        'APPLICATION_BASE_CREATE',
        ({ why, body, defaults = {} }) => {
          log('[ReduxHandler] [subscribe->APPLICATION_BASE_CREATE]', { why, body, defaults })
          ;(async () => {
            const r = await window.sticky.applications.blocks.renderInlineEventsButton(
              [
                {
                  'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                  'config': {
                    'label': 'Name',
                    'labelForm': 'name',
                    'type': 'Text',
                    'value': defaults.name,
                    'disabled': false,
                    'required': true,
                    'stashUser': false,
                    'isHidden': false
                  }
                },
                {
                  'id': '4e6f993d-ddc9-43ea-aadb-7080bee647bc',
                  'config': {
                    'label': 'Icon',
                    'labelForm': 'icon',
                    'buttonText': 'Upload',
                    'required': true,
                    'stashUser': false,
                    'customValue': !defaults.icon ? `uploadImage:${window.sticky.cdn}/application-blocks/new.svg` : `uploadImage:${defaults.icon}`
                  }
                },
                user.isLoggedInAsPartner && {
                  'id': '100ada2b-1375-42c0-958a-49e7187a7d73',
                  'config': {
                    'label': 'Add as a partner, not just for me',
                    'labelForm': 'asAPartner',
                    'stashUser': false,
                    'value': true
                  }
                }
              ]
                .filter(_ => _),
              'Add'
            )
            if (!r) {
              return
            }
            const payload = {
              ...body,
              name: r.name,
              icon: r.icon,
              asAPartner: r.asAPartner === 'Yes',
            }
            dispatch('LOADING')
            try {
              const r = await window.sticky.applications.bases.create(payload)
              dispatch('APPLICATION_BASE_CREATE_GOOD', { why, id: r.id })
            } catch ({ message }) {
              dispatch('SHOW_MESSAGE', { message: <p>{message}</p>, canBeBadded: '' })
            }
            dispatch('STOP_LOADING')
          })()
        }
      ),
      subscribe(
        'USER_DELETE',
        ({ otherUser }) => {
          log('[ReduxHandler] [subscribe->USER_DELETE]', { otherUser })
          this.setState({
            GET_INPUT: {
              why: 'user',
              string: '',
              hint: <>Are you absolutely sure?<br /><br />There is no way back from here.<br /><br /><strong>Please type "okay" to confirm:</strong></>,
              entity: otherUser,
              doValidate: false
            }
          })
        }
      ),
      subscribe(
        'SURE_DELETE_GOOD',
        ({ why, entity }) => {
          why === 'partner' && (async () => {
            await window.sticky.internals.partners.remove(entity)
            dispatch('PARTNER_DELETE_GOOD')
          })()
          why === 'APPLICATION_BASE_DELETE' && (async () => {
            try {
              await window.sticky.applications.bases.remove(entity)
              dispatch('APPLICATION_BASE_DELETE_GOOD')
            } catch ({ message }) {
              dispatch('SHOW_MESSAGE', { message: <p>{message}</p>, canBeBadded: '' })
            }
          })()
          why === 'APPLICATION_BASE_SET_DELETE' && (async () => {
            try {
              await window.sticky.applications.baseSets.remove(entity)
              dispatch('APPLICATION_BASE_SET_DELETE_GOOD')
            } catch ({ message }) {
              dispatch('SHOW_MESSAGE', { message: <p>{message}</p>, canBeBadded: '' })
            }
          })()
        }
      ),
      subscribe('CHOOSE_APPLICATION_BASE_OR_BASE_SET', () => this.setState({ CHOOSE_APPLICATION_BASE_OR_BASE_SET: true })),
      subscribe('NEW_BASE_PRODUCT', () => this.setState({ NEW_BASE_PRODUCT: true })),
      subscribe(
        'MUST_TYPE',
        ({ why, hint, entity, what }) => {
          log('[ReduxHandler] [subscribe->MUST_TYPE]', { why, hint, entity, what })
          this.setState({
            MUST_TYPE: {
              why,
              hint,
              entity,
              mustType: what
            }
          })
        }
      ),
      subscribe(
        'SURE_DELETE',
        ({ why, hint, entity }) => {
          log('[ReduxHandler] [subscribe->SURE_DELETE]', { why, hint, entity })
          this.setState({
            SURE_DELETE: {
              why,
              hint,
              entity
            }
          })
        }
      ),
      subscribe(
        'GET_INPUT',
        ({ why, type, string, hint, entity, selectAll = false, doValidate }) => {
          this.setState({
            GET_INPUT: {
              why,
              type,
              string,
              hint,
              entity,
              selectAll,
              doValidate
            }
          })
        }
      ),
      subscribe(
        'GET_PRICE',
        ({ why, hint, price, currency }) => {
          log('[ReduxHandler] [subscribe->GET_PRICE]', { why, hint, price, currency })
          this.setState({
            GET_PRICE: {
              why,
              hint,
              price,
              currency
            }
          })
        }
      ),
      subscribe(
        'CHOOSE_APPLICATIONS',
        ({ why, applicationIds, allowNone, limit = 1 }) => {
          this.refresh()
            .then(() => {
              const applicableEntities = entities.applications.filter(t => {
                return (Array.isArray(applicationIds) ? applicationIds.includes(t.id) : true)
              })
              log('[ReduxHandler] [subscribe->CHOOSE_APPLICATIONS]', { why, applicationIds, applicableEntities })
              if (applicableEntities.length === 0) {
                dispatch('CHOOSE_APPLICATIONS_GOOD', { applications: [], why })
                return
              }
              if (applicableEntities.length === 1) {
                dispatch('CHOOSE_APPLICATIONS_GOOD', { applications: [applicableEntities[0]], why })
                return
              }
              this.setState({
                CHOOSE_APPLICATIONS: {
                  applicableEntities,
                  why,
                  allowNone,
                  limit
                }
              })
            })
        }
      ),
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, string, entity }) => {
          why === 'user' && string === 'okay' && (async () => {
            try {
              await window.sticky.applications.blocks.showMessage('Your dashboard has now been deleted.', undefined, false, false)
              await window.sticky.users.remove(entity)
            } catch ({ message }) {
              dispatch('SHOW_MESSAGE', { message: <p>{message}</p> })
            }
          })()
          why === 'federatedUser' && (async () => {
            entity.patch({ password: string })
            dispatch('SAVE_FEDERATED_USER', { entity, props: ['password'] })
          })()
        }
      ),
      ...ABSTRACTED.map(([name, imported]) => subscribe(name, (props) => imported.trigger({ ...props, user, entities, thisState: state, setState: this.setState, dispatch, subscribe })))
    ]

    const { onReady } = this.props
    onReady && onReady(entities)
  }

  componentWillUnmount () {
    this.subscriptions && this.subscriptions.forEach(s => s())
    this.unblinkAll()
  }

  onChosenApplications (ids) {
    const { entities, CHOOSE_APPLICATIONS } = state
    const applications = entities.applications.filter(_ => ids.includes(_.id))
    dispatch('CHOOSE_APPLICATIONS_GOOD', { applications, why: CHOOSE_APPLICATIONS.why })
    this.setState({
      CHOOSE_APPLICATIONS: undefined
    })
  }

  render () {
    const {
      user,
      entities,
      detailss,

      redirect,
      redirectCleanSlate,

      MUST_TYPE,
      SURE_DELETE,
      GET_INPUT,
      GET_PRICE,

      CHOOSE_APPLICATION_BASE_OR_BASE_SET,
      CHOOSE_APPLICATIONS,

      partnerUpdating
    } = state

    const rdic = {
      state,
      setState: this.setState,
      entities,
      user,
      dispatch,
      subscribe,
      refresh: this.refresh
    }

    return (<StyledReduxHandler>
      {ABSTRACTED
        .filter(([name, IMPORTED]) => IMPORTED.render)
        .map(([name, IMPORTED]) => {
          return <IMPORTED.render key={name} {...rdic} />
        })}
      {detailss && (
        <DetailsModal
          detailss={detailss.detailss}
          canEdit={detailss.canEdit}
          onSave={() => {
            this.setState({ detailss: undefined })
            dispatch('DETAILSS_GOOD')
          }}
          onCancel={() => {
            this.setState({ detailss: undefined })
            dispatch('DETAILSS_GOOD')
          }}
        />
      )}
      {redirect && (
        <Redirect to={redirect} cleanSlate={redirectCleanSlate} />
      )}
      {partnerUpdating && <PartnerModal
        partner={partnerUpdating}
        onUpdate={(key, value) => {
          partnerUpdating.patch({ [key]: value })
          this.forceUpdate()
        }}
        onSave={() => {
          window.sticky.internals.partners.save(partnerUpdating)
          this.setState({ partnerUpdating: undefined })
          dispatch('PARTNER_UPDATE_GOOD')
        }}
        onCancel={() => this.setState({ partnerUpdating: undefined })}
      />}
      {MUST_TYPE && <MustTypeModal
        hint={MUST_TYPE.hint}
        mustTypeString={MUST_TYPE.mustType}
        onDone={() => {
          this.setState({ MUST_TYPE: undefined })
          dispatch('MUST_TYPE_GOOD', { why: MUST_TYPE.why, entity: MUST_TYPE.entity })
        }}
        onCancel={() => this.setState({ MUST_TYPE: undefined })}
      />}
      {SURE_DELETE && <SureDeleteModal
        hint={SURE_DELETE.hint}
        onDone={() => {
          this.setState({ SURE_DELETE: undefined })
          dispatch('SURE_DELETE_GOOD', { why: SURE_DELETE.why, entity: SURE_DELETE.entity })
        }}
        onCancel={() => {
          this.setState({ SURE_DELETE: undefined })
          dispatch('SURE_DELETE_BAD', { why: SURE_DELETE.why, entity: SURE_DELETE.entity })
        }}
      />}
      {GET_INPUT && <InputModal
        type={GET_INPUT.type}
        string={GET_INPUT.string}
        hint={GET_INPUT.hint}
        selectAll={GET_INPUT.selectAll}
        doValidate={GET_INPUT.doValidate}
        onDone={(string) => {
          this.setState({ GET_INPUT: undefined })
          dispatch('GET_INPUT_GOOD', { why: GET_INPUT.why, entity: GET_INPUT.entity, string })
        }}
        onCancel={() => this.setState({ GET_INPUT: undefined })}
      />}
      {GET_PRICE && <EnterPriceModal
        hint={GET_PRICE.hint}
        price={GET_PRICE.price}
        currency={GET_PRICE.currency}
        onGood={(price) => {
          this.setState({ GET_PRICE: undefined })
          dispatch('GET_PRICE_GOOD', { why: GET_PRICE.why, price })
        }}
        onCancel={() => this.setState({ GET_PRICE: undefined })}
      />}
      {CHOOSE_APPLICATION_BASE_OR_BASE_SET && (
        <>
          <Modal
            className='choose-application-base-or-base-set'
            onClose={() => {
              this.setState({ CHOOSE_APPLICATION_BASE_OR_BASE_SET: undefined })
            }}
            canBeClosed={!user.federatedUserCan('lock--create-application', false)}
          >
            <ChooseApplicationBaseOrBaseSet
              user={user}
              onChoose={({ type, what, config }) => {
                this.setState({ CHOOSE_APPLICATION_BASE_OR_BASE_SET: undefined })
                what && dispatch('CHOOSE_APPLICATION_BASE_OR_BASE_SET_GOOD', { type, what, config })
              }}
            />
          </Modal>
        </>
      )}
      {CHOOSE_APPLICATIONS && (
        <Modal
          className='choose-entity'
          onClose={() => this.setState({ CHOOSE_APPLICATIONS: undefined })}
        >
          <H1>{_(CHOOSE_APPLICATIONS.limit === 1 ? 'CHOOSE_APPLICATION' : 'CHOOSE_APPLICATIONS')}</H1>
          <ApplicationChooser
            applicableEntities={CHOOSE_APPLICATIONS.applicableEntities}
            allowNone={CHOOSE_APPLICATIONS.allowNone}
            limit={CHOOSE_APPLICATIONS.limit}
            onChange={this.onChosenApplications}
          />
        </Modal>
      )}
    </StyledReduxHandler>)
  }
}
ReduxHandler.propTypes = {
  onReady: PropTypes.func
}
