import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { icons, tags, Modal, Form, Input, PriceInput, Button, Switch } from '@openbox-app-shared'
import MediaEditor from '../mediaEditor'
import SubProducts from '../SubProducts'
import Box from '../Box'
import H2 from '../H2'
import { TagChooser } from '../TagChooser'

const StyledModal = styled.div`
  ${props => css`
    .component--modal {
      max-width: ${props.displayWide ? '36rem' : '24rem'};
    }
  `}
  .component--input.option-name input {
    width: 12rem;
  }
  .component--input.option-delta input {
    width: 6rem;
  }
  .component--input.option-description textarea {
    width: 20rem;
  }
  .component--button.save {
    display: block;
    width: auto;
    margin: 2rem auto 0 auto;
  }
  .their-id {
    margin-bottom: 1rem;
  }
`

export default function ThisModal ({ user, option, products, onSave, onCancel }) {
  const [newOption, setNewOption] = useState({ ...option })
  const [isValid, setIsValid] = useState(true)
  const showInLinearOperatorView = user.can('show-linear-operator-view')
  const tagsAsSet = window.sticky.newPatchableSet(newOption.tags || [])
  return (
    <StyledModal displayWide={showInLinearOperatorView}>
      <Modal onClose={onCancel}>
        <Form
          onChange={(name, value) => {
            const noPayload = {
              ...newOption,
              [name]: value
            }
            const isReallyValid = [
              noPayload.name.length > 0
            ].every(e => e)
            isValid !== isReallyValid && setIsValid(isReallyValid)
            setNewOption(noPayload)
          }}
          onSubmit={() => onSave(newOption)}
          autoFocus
        >
          <Input
            name='name'
            label='Name'
            className='option-name'
            value={newOption.name}
            selectAll
          />
          <PriceInput
            label='Price difference (+ / -)'
            name='delta'
            className='option-delta'
            value={newOption.delta}
          />
          <Switch
            name='forSale'
            checked={newOption.forSale}
          >
            For sale
          </Switch>
          <Input
            label='Description'
            type='textarea'
            name='description'
            className='option-description'
            value={newOption.description}
          />
          <TagChooser
            label='Sticky labels'
            all={tags.filter(t => !t.id.startsWith('vat--') && t.canShow(user))}
            set={tagsAsSet}
            onUpdate={(id) => {
              tagsAsSet.toggle(id)
              setNewOption({ ...newOption, tags: Array.from(tagsAsSet) })
            }}
          />
          <MediaEditor
            name='media'
            defaultToDoLinear={showInLinearOperatorView}
            showChooseResource={false}
            media={newOption.media}
            user={user}
          />
          <Box>
            <H2>Sub products</H2>
            <SubProducts
              subProducts={newOption.subProducts}
              allProducts={products}
              onChange={subProducts => {
                setNewOption({ ...newOption, subProducts })
              }}
            />
          </Box>
          {user.connections.length > 0 && <>
            <Input name='theirId' className='their-id' label='Your ID' value={newOption.theirId} />
          </>}
          <Button
            backgroundColor='#a55eea'
            className='save'
            icon={icons.inverted.check}
            onClick={() => onSave(newOption)}
            disabled={!isValid}
          >
            Done
          </Button>
        </Form>
      </Modal>
    </StyledModal>
  )
}

ThisModal.propTypes = {
  user: PropTypes.object.isRequired,
  option: PropTypes.object.isRequired,
  products: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}
