import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { icons, Banner, Modal, Form, Input, Dropdown, NumberInput, List, ListItem, Price, Button, Switch } from '@openbox-app-shared'
import { subscribe } from '../../redux'
import QuestionOption from './QuestionOption'
import Box from '../Box'

const StyledModal = styled.div`
  .component--modal {
    max-width: 24rem;
  }
  .component--list {
    min-height: 7.75rem;
    padding: 0.5rem;
    border: 2px solid #F0F1F3;
    border-radius: 4px;
  }
  .component--button {
    display: block;
    width: auto;
  }
  .component--box.can-be-duplicated-text {
    margin-top: 1rem;
    .component--input {
      margin-top: 1rem;
    }
  }
  .component--button.save {
    margin: 2rem auto 0 auto;
  }
  .their-id {
    margin-bottom: 1rem;
  }
  .component--input.type--number {
    input {
      width: 6rem;
    }
  }
`

export default function ThisModal({ user, product, products, question, onUpdate, onSave, onCancel }) {
  const [currentOption, setCurrentOption] = useState()
  const [applyChecklistMinimum, setApplyChecklistMinimum] = useState(question.checklistMinimum > 0 ? true : false)
  const [applyChecklistMaximum, setApplyChecklistMaximum] = useState(question.checklistMaximum > 0 ? true : false)
  useEffect(() => {
    const subscriptions = [
      subscribe(
        'GET_INPUT_GOOD',
        ({ why, entity, string }) => {
          why === 'question-rename' && (() => {
            question.options = [...question.options]
            question.options[entity].name = string
            onUpdate()
          })()
        }
      )
    ]
    return () => {
      subscriptions.forEach(s => s())
    }
  })

  return (
    <>
      <StyledModal>
        <Modal onClose={onCancel}>
          {user.can('show-linear-operator-view') && question.type === 'options' && (
            <Banner>
              <p>"Checklist" questions are new and you have the permission <code>show-linear-operator-view</code>. Payments in "Linear live payments" do not support "Checklist" questions yet. You are in the danger zone.</p>
            </Banner>
          )}
          <Form
            onChange={(name, value) => {
              if (name === 'type' && value === 'options') {
                question.canBeDuplicatedText = undefined
                question.canBeDuplicatedMaximum = undefined
              }
              question[name] = value
              // if (typeof question.canBeDuplicatedText === 'string') {
              //   const newPart = question.question.toLowerCase()
              //   question.canBeDuplicatedText = newPart.length > 0 ? `Add another ${newPart}` : 'Add another'
              // }
              onUpdate()
            }}
            onSubmit={onSave}
            autoFocus
          >
            <Input
              name='question'
              label='Name'
              value={question.question}
              selectAll
            />
            <Dropdown
              name='type'
              label='Type'
              items={[
                {
                  id: 'option',
                  name: 'Choose one'
                },
                {
                  id: 'options',
                  name: 'Checklist'
                }
              ]}
              selected={question.type}
            />
            {
              question.type === 'options' && (
                <>
                  <>
                    <Switch
                      name='setMinimum'
                      checked={applyChecklistMinimum}
                      onChange={() => {
                        if (applyChecklistMinimum) {
                          question.checklistMinimum = undefined
                        } else {
                          question.checklistMinimum = 1
                        }
                        setApplyChecklistMinimum(!applyChecklistMinimum)
                      }}
                    >
                      Set checklist minimum?
                    </Switch>
                    {applyChecklistMinimum && <NumberInput
                      label='Set minimum'
                      value={question.checklistMinimum}
                      max={question.options.length}
                      onChange={v => {
                        if (v === 0) {
                          setApplyChecklistMinimum(false)
                          question.checklistMinimum = undefined
                        } else {
                          question.checklistMinimum = v
                        }
                        onUpdate()
                      }}
                    />}
                  </>
                  <>
                    <Switch
                      name='setMaximum'
                      checked={applyChecklistMaximum}
                      onChange={() => {
                        if (applyChecklistMaximum) {
                          question.checklistMaximum = undefined
                        } else {
                          question.checklistMaximum = question.options.length
                        }
                        setApplyChecklistMaximum(!applyChecklistMaximum)
                      }}
                    >
                      Set checklist maximum?
                    </Switch>
                    {applyChecklistMaximum && <NumberInput
                      label='Set maximum'
                      value={question.checklistMaximum}
                      max={question.options.length}
                      onChange={v => {
                        if (v === 0) {
                          setApplyChecklistMaximum(false)
                          question.checklistMaximum = undefined
                        } else {
                          question.checklistMaximum = v
                        }
                        onUpdate()
                      }}
                    />}
                  </>
                </>
              )
            }
            <List
              draggable
              onDrag={(from, to) => {
                window.sticky.swapArrayElements(question.options, from, to)
                onUpdate()
              }}
            >
              {question.options.map((o, index) => {
                const tags = o.delta !== 0
                  ?
                  [{
                    name: <>+ <Price currency={product.currency} price={o.delta} /></>,
                    color: '#A9B7C6'
                  }]
                  :
                  []
                return <ListItem
                  id={`index--${index}`}
                  key={`index--${index}`}
                  icon={icons.generic.listItem}
                  tags={tags}
                  actions={['copy', 'delete']}
                  onChoose={() => {
                    setCurrentOption(index)
                  }}
                  onAction={(string, action) => {
                    const actions = {
                      'delete': () => {
                        const newArray = [...question.options]
                        newArray.splice(index, 1)
                        question.options = newArray
                        if (applyChecklistMinimum && question.checklistMinimum > question.options.length) {
                          question.checklistMinimum = question.options.length
                        }
                        if (applyChecklistMaximum && question.checklistMaximum > question.options.length) {
                          question.checklistMaximum = question.options.length
                        }
                        if (question.options.length === 0) {
                          question.checklistMinimum = undefined
                          question.checklistMaximum = undefined
                          setApplyChecklistMinimum(false)
                          setApplyChecklistMaximum(false)
                        }
                        onUpdate()
                      },
                      'copy': () => {
                        const theIndex = parseInt(string.substring('index--'.length), 10)
                        const toCopy = JSON.parse(JSON.stringify(question.options[theIndex]))
                        toCopy.name = `Copy of ${toCopy.name}`
                        question.options.splice(theIndex + 1, 0, toCopy)
                        onUpdate()
                      }
                    }
                    actions[action]()
                  }}
                >
                  {o.name}
                </ListItem>
              })}
            </List>
            <Button
              isSecondary
              icon={icons.generic.add}
              onClick={() => {
                question.options.push({ name: `Option ${question.options.length + 1}`, delta: 0, media: [], subProducts: [] })
                setCurrentOption(question.options.length - 1)
                onUpdate()
              }}
            >
              Option
            </Button>
            {question.type === 'option' && <Box className='can-be-duplicated-text'>
              <Switch
                onChange={v => {
                  question.canBeDuplicatedText = v ? `Add another ${question.question.toLowerCase()}` : undefined
                  question.canBeDuplicatedMaximum = v ? 1 : undefined
                  onUpdate()
                }}
                checked={typeof question.canBeDuplicatedText === 'string'}
              >
                Let people add another "{question.question}"
              </Switch>
              {typeof question.canBeDuplicatedText === 'string' && (
                <Input
                  label='Button text'
                  value={question.canBeDuplicatedText}
                  onChange={v => {
                    question.canBeDuplicatedText = v
                    onUpdate()
                  }}
                />
              )}
              {typeof question.canBeDuplicatedMaximum === 'number' && (
                <NumberInput
                  label='Maximum'
                  value={question.canBeDuplicatedMaximum}
                  onChange={v => {
                    question.canBeDuplicatedMaximum = v
                    onUpdate()
                  }}
                />
              )}
            </Box>}
            {user.connections.length > 0 && <>
              <hr />
              <Switch
                name='connectionHandleAsProduct'
                checked={question.connectionHandleAsProduct}
              >
                Connections should handle as a product
              </Switch>
              <Input name='theirId' className='their-id' label='Your ID' value={question.theirId} />
            </>}
            <Button
              backgroundColor='#a55eea'
              className='save'
              icon={icons.inverted.check}
              onClick={onSave}
              disabled={!question.isValid}
            >
              Done
            </Button>
          </Form>
        </Modal>
      </StyledModal>
      {
        typeof currentOption === 'number' && (
          <QuestionOption
            user={user}
            option={question.options[currentOption]}
            products={products}
            onSave={(newOption) => {
              question.options[currentOption] = {
                ...question.options[currentOption],
                ...newOption
              }
              onUpdate()
              setCurrentOption(undefined)
            }}
            onCancel={() => {
              setCurrentOption(undefined)
            }}
          />
        )
      }
    </>
  )
}

ThisModal.propTypes = {
  user: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array,
  question: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}
