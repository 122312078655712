/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Banner, Details, Button } from '@openbox-app-shared'
import Box from '../Box'
import { dispatch } from '../../redux'

const StyledView = styled.div`
  .component--button.go-back {
    margin-bottom: 1rem;
  }
  .gateways {
    > * {
      img {
        display: block;
        height: 4rem;
        margin-bottom: 1rem;
      }
      > div > p {
        font-size: 85%;
        line-height: 1.2rem;
        text-align: justify;
      }
      > * + * {
        margin-top: 1rem;
      }
      > div > p + p {
        margin-top: 0.5rem;
      }
    }
    > * + * {
      margin-top: 1rem;
    }
  }
  @media only screen and (min-width: 1160px) {
    .gateways {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;
      > * {
        margin-top: 0 !important;
      }
    }
  }
`

function Gateway ({ user, entity, gateway, triggerProps, currentGateway, setView, onUpdate, onChoose, doFocus }) {
  const isThisGateway = currentGateway === gateway
  const gatewayDoBrand = gateway.isPartnerBrandable && user.partnerCan('brand-gateway')
  const gatewayLogoUrl = gatewayDoBrand ? user.partner.logoUrl : (gateway.logo || gateway.icon)
  const gatewayName = gatewayDoBrand ? user.partner.name : gateway.name
  return (
    <Box key={gateway.id}>
      {isThisGateway && (
        <Banner mood='good'><p>{gatewayName} is your current payment provider.</p></Banner>
      )}
      <img src={gatewayLogoUrl} alt={gatewayName} />
      {gateway.termsPreContract && <Details details={gateway.termsPreContract(entity)} />}
      {gateway.description && (
        <div dangerouslySetInnerHTML={{ __html: gateway.description }} />
      )}
      {!isThisGateway && gateway.canOnboardChoose && <Button
        className={doFocus ? 'openbox--pulsing-2' : undefined}
        backgroundColor={gateway.onboarding.color || '#1A1F35'}
        onClick={() => onChoose(gateway)}
      >
        Choose →
      </Button>}
      {gateway.canOnboardFromChooseName && (
        <Button
          onClick={async () => {
            const values = await window.sticky.applications.blocks.renderInlineEventsButton(
              gateway.onboarding.trigger.applicationBlocks({ user }),
              'Activate'
            )
            values && dispatch('TRIGGER', { trigger: 'gateway--onboardTrigger', body: { id: gateway.id, values: { ...values, ...triggerProps } } })
          }}
          backgroundColor='white'
          color={gateway.onboarding.color || '#1A1F35'}
        >
          {gateway.canOnboardFromChooseName}
        </Button>
      )}
    </Box>
  )
}

function Gateways ({ gateways, gateway, ...props }) {
  return (
    <div className='gateways'>
      {gateways.map((gw, i) => {
        return <Gateway {...props} key={`gw--${gw.id}`} gateway={gw} currentGateway={gateway} doFocus={i === 0} />
      })}
    </div>
  )
}

export default function StateChoose1 ({ user, entity, triggerProps, gateways, gateway, setView, onUpdate, onChoose }) {
  const gwTierProps = {
    user,
    entity,
    gateway,
    setView,
    onUpdate,
    onChoose,
    triggerProps
  }
  const partnerGateway = user.partner ? gateways.find(gw => gw.id === user.partner.gatewayId) : undefined
  const gatewayIds = new Set()
  gateway && gatewayIds.add(gateway.id)
  partnerGateway && (() => {
    gatewayIds.add(partnerGateway.id)
    gatewayIds.add('GATEWAY_NOOP')
  })()
  !partnerGateway && (() => {
    gateways
      .filter(_ => _.isReallyImportant)
      .forEach(_ => {
        gatewayIds.add(_.id)
      })
  })()

  const gatewaysToGo = Array.from(gatewayIds).map(gwId => gateways.find(gw => gw.id === gwId))
  return (
    <StyledView>
      {gateway && <Button
        onClick={() => setView('StateView')}
        isSecondary
        className='go-back'
      >
        ← Go back
      </Button>}
      <Gateways {...gwTierProps} gateways={gatewaysToGo} />
    </StyledView>
  )
}
StateChoose1.propTypes = {
  user: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  triggerProps: PropTypes.object,
  gateways: PropTypes.arrayOf(PropTypes.object).isRequired,
  gateway: PropTypes.object,
  setView: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onChoose: PropTypes.func.isRequired
}
