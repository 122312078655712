/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import GenericModal from '../../modals/generic'
import { Form, Input, PhoneNumberInput, EmailInput, Button, isEmailValid, isPhoneNumberValid } from '@openbox-app-shared'

import _ from '../../../_'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    max-width: 32rem;
    padding: 4rem 1rem 1rem 1rem;
    .component--button {
      margin: 1rem auto 0 auto;
    }
    > .component--form {
      max-width: 16rem;
      margin: 0 auto 1rem; auto;
    }
  }
`

const getFormHttpBody = (params) => {
  return Object.keys(params).map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&')
}

async function createShortLink (whichUrl, { name, paymentId }) {
  return await window.sticky.shortLinks.create({ whichUrl, name, paymentId, type: 'REDIRECT' })
}

async function payGoToNative (whichUrl, paymentId, slName) {
  try {
    dispatch('LOADING')
    const shortLink = await createShortLink(whichUrl, { name: slName, paymentId })
    const didShare = await window.sticky.shortLinks.shareNative(shortLink.getUrl)
    didShare && dispatch(
      'PAY_WAIT_ORIGIN',
      {
        why: 'PAY_GO_TO',
        extra: {
          referenceKey: 'probablyFirstPaymentIdsDelete',
          referenceValue: paymentId,
          messages: ['Tell the consumer to use the link...']
        }
      }
    )
  } catch ({ message }) {
    window.sticky.applications.blocks.showError(message, true)
  } finally {
    dispatch('STOP_LOADING')
  }
}

async function payGoTo (whichUrl, paymentId, destination, payload, slName) {
  try {
    dispatch('LOADING')
    const { id: shortLinkId } = await createShortLink(whichUrl, { name: slName, paymentId })
    await window.sticky.internals.trigger(
      'share',
      {
        entity: 'short-link',
        entityId: shortLinkId,
        destination,
        ...payload
      }
    )
    dispatch(
      'PAY_WAIT_ORIGIN',
      {
        why: 'PAY_GO_TO',
        extra: {
          referenceKey: 'probablyFirstPaymentIdsDelete',
          referenceValue: paymentId,
          messages: ['Tell the consumer to use the link...']
        }
      }
    )
  } catch ({ message }) {
    window.sticky.applications.blocks.showError(message, true)
  } finally {
    dispatch('STOP_LOADING')
  }
}

function ThisModal ({ user, onChange, onClose, payment, userPreferences }) {
  const [userPaymentId, setUserPaymentId] = useState(payment.userPaymentId)
  const [paymentName, setPaymentName] = useState(payment.name)
  const [paymentPhone, setPaymentPhone] = useState(payment.phone)
  const [paymentEmail, setPaymentEmail] = useState(payment.email)

  const slName = [userPaymentId, paymentName, paymentPhone, paymentEmail].filter(_ => _).join(' / ')

  const { payByLinkRequired } = userPreferences
  const searchParams = {
    paymentId: payment.id
  }
  const whichUrl = window.sticky.applications.test(payment.application.id, getFormHttpBody(searchParams))

  return (
    <StyledModal>
      <GenericModal onGood={onClose} badText='' goodText={null}>
        <Form
          onChange={(k, v) => {
            k === 'userPaymentId' && setUserPaymentId(v)
            k === 'name' && setPaymentName(v)
            k === 'phone' && setPaymentPhone(v)
            k === 'email' && setPaymentEmail(v)
            onChange(k, v)
          }}
        >
          {window.sticky.shortLinks.canShareNative() && (
            <>
              <Button
                inlineIconString={window.sticky.internals.icons.get('share')}
                onClick={() => {
                  payGoToNative(whichUrl, payment.id, slName)
                }}
              >
                Share
              </Button>
              <hr />
            </>
          )}
          <PhoneNumberInput
            name='phone'
            label='Phone number'
            value={paymentPhone}
            doValidate={false}
          />
          <Button
            disabled={!isPhoneNumberValid(paymentPhone) || (payByLinkRequired ? (userPaymentId || '').trim() === '' : false )}
            inlineIconString={window.sticky.internals.icons.get('sms')}
            onClick={() => {
              payGoTo(whichUrl, payment.id, 'sms', { to: paymentPhone }, slName)
            }}
          >
            Send SMS
          </Button>
          <hr/>
          <EmailInput
            name='email'
            label='Email'
            value={paymentEmail}
            fireIfInvalid
          />
          <Button
            disabled={!isEmailValid(paymentEmail) || (payByLinkRequired ? (userPaymentId || '').trim() === '' : false )}
            inlineIconString={window.sticky.internals.icons.get('email')}
            onClick={() => {
              payGoTo(whichUrl, payment.id, 'email', { to: paymentEmail }, slName)
            }}
          >
            Send email
          </Button>
          <hr />
          <Input
            name='name'
            label='Name'
            value={paymentName}
          />
          <Input
            name='userPaymentId'
            label={`Your ID ${payByLinkRequired ? '(reference)' : '(optional reference)'}`}
            value={userPaymentId}
            isValid={(userPaymentId || '') !== ''}
          />
        </Form>
      </GenericModal>
    </StyledModal>
  )
}

let subscriptions = []

export default {
  trigger: ({ setState, subscribe, why, payment, userPreferences }) => {
    subscriptions = [
      subscribe(
        'PAY_WAIT_ORIGIN_GOOD',
        ({ why }) => {
          why === 'PAY_GO_TO' && (async () => {
            subscriptions.forEach(s => s())
            setState({
              PAY_GO_TO: undefined
            })
          })()
        }
      )
    ]
    setState({ PAY_GO_TO: { why, payment, userPreferences } })
  },
  render: function ThisAction ({ user, state, setState, dispatch }) {
    const { PAY_GO_TO } = state
    if (!PAY_GO_TO) {
      return null
    }
    const onChange = (k, v) => {
      dispatch('PAY_GO_TO_ON_CHANGE', { k, v })
    }
    const { payment, userPreferences } = PAY_GO_TO
    return (
      <>
        <ThisModal
          user={user}
          userPreferences={userPreferences}
          onChange={onChange}
          onClose={() => {
            subscriptions.forEach(s => s())
            setState({
              PAY_GO_TO: undefined
            })
          }}
          payment={payment}
        />
      </>
    )
  }
}
